import jwtAuthAxios from "../../services/jwtAuth";
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

export const getallAdminKrs = createAsyncThunk("adminKrs/getAllAdminKrs", 
async({ page, pageSize, search }, thunkAPI) => {
    try{
        let token = await localStorage.getItem('auth_token');
        jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        const searchParams = search === undefined ? "" : search;
        const url = `v1/krs/pageable?page=${page}&size=${pageSize}`
        const response = await jwtAuthAxios.get(url)
        return response.data
    } catch(response){
        throw response
    }
});

export const getDetailKrs = createAsyncThunk("adminKrs/getDetailKrs", async(id) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/map/krs/detail/${id}`);
    return response.data;
});

export const getDataDetail = createAsyncThunk("adminKrs/getDataDetail", async(id) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/krs/detail/${id}`);
    return response.data;
});


export const saveAdminKrs = createAsyncThunk("adminKrs/save", async(payload) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.post('v1/krs',payload);
    return response.data;
});

export const updateAdminKrs = createAsyncThunk("adminKrs/update", async(request) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.put('v1/krs',request);
    return response.data;
});


export const deleteAdminKrs = createAsyncThunk("adminKrs/delete", async(id) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    await jwtAuthAxios.delete(`v1/map/krs/${id}`);
    return id;
});

const initialState = {
    loading: false,
    dataAll: [],
    dataKrsId: [],
    formPeriodekrs: [],
    dataSaveKrs: [],
    dataUpdateKrs: [],
    dataDetailKrsId:[]
}

const adminKrsSlice = createSlice({
    name:"adminKrs",
    initialState: initialState,
    reducers: {
        onDataChange: (state, action) => {
            state.formPeriodekrs = action.payload
        },
        onDeleteChange: (state, action) => {
            state.formPeriodekrs = null  
        }
    },
    extraReducers: (builder) => {
    builder
        .addCase(getallAdminKrs.pending, (state) => {
            state.loading = true;
        })
        .addCase(getallAdminKrs.fulfilled, (state, action) => {
            state.dataAll = action.payload;
            state.loading = false;
        })
        .addCase(getallAdminKrs.rejected, (state, action) => {
            state.loading = false;
            console.error("Error:", action.error);
        })
    builder
        .addCase(getDetailKrs.pending, (state) => {
            state.loading = true;
        })
        .addCase(getDetailKrs.fulfilled, (state, action) => {
            state.dataKrsId = action.payload;
            state.loading = false;
        })
        .addCase(getDetailKrs.rejected, (state, action) => {
            state.loading = false;
            console.error("Error:", action.error);
        })
    builder
        .addCase(getDataDetail.pending, (state) => {
            state.loading = true;
        })
        .addCase(getDataDetail.fulfilled, (state, action) => {
            state.dataDetailKrsId = action.payload;
            state.loading = false;
        })
        .addCase(getDataDetail.rejected, (state, action) => {
            state.loading = false;
            console.error("Error:", action.error);
        })
    builder
        .addCase(deleteAdminKrs.pending, (state) => {
            state.loading = true;
        })
        .addCase(deleteAdminKrs.fulfilled, (state, action) => {
            state.loading = false;
        })
        .addCase(deleteAdminKrs.rejected, (state, action) => {
            state.loading = false;
            console.error("Error:", action.error);
        })
    builder
        .addCase(saveAdminKrs.pending, (state) => {
            state.loading = true;
        })
        .addCase(saveAdminKrs.fulfilled, (state, action) => {
            state.dataSaveKrs = action.payload
            state.loading = false;
        })
        .addCase(saveAdminKrs.rejected, (state, action) => {
            state.loading = false;
            console.error("Error:", action.error);
        })
    builder
        .addCase(updateAdminKrs.pending, (state) => {
            state.loading = true;
        })
        .addCase(updateAdminKrs.fulfilled, (state, action) => {
            state.dataUpdateKrs = action.payload
            state.loading = false;
        })
        .addCase(updateAdminKrs.rejected, (state, action) => {
            state.loading = false;
            console.error("Error:", action.error);
        })
    }
})

export const {
    onDataChange,
    onDeleteChange
} = adminKrsSlice.actions

export const adminKrsSelector = (state) => state.adminKrs;
export default adminKrsSlice.reducer;