import jwtAuthAxios from "../../services/jwtAuth";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { generateTemplate } from "configs/utils/Excel";
import { DATE_FORMAT_DD_MM_YYYY_T_HH_mm_ss } from "constants/DateConstant";
import dayjs from 'dayjs';

export const getAllMahasiswa = createAsyncThunk("mahasiswa/getAllMahasiswa", 
	async (_, { rejectWithValue }) => {
		try {
			const token = await localStorage.getItem('auth_token');
			jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
			const response = await jwtAuthAxios.get('v1/mahasiswa');
			return response.data;
		} catch (e) {
			rejectWithValue({
				message: e.data.message || e.message
			})
		}
	}
);

export const getListMahasiswa = createAsyncThunk("mahasiswa/getListMahasiswa", 
	async (_, { rejectWithValue }) => {
		try {
			const token = await localStorage.getItem('auth_token');
			jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
			const response = await jwtAuthAxios.get('v1/mahasiswa/detail/all');
			return response.data;
		} catch (e) {
			rejectWithValue({
				message: e.data.message || e.message
			})
		}
	}
);



export const getMahasiswaById = createAsyncThunk("mahasiswa/getMahasiswaById", 
	async (id, { rejectWithValue }) => {
		try {
			const token = await localStorage.getItem('auth_token');
			jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
			const response = await jwtAuthAxios.get(`v1/biodata-mahasiswa/${id}`);
			return response.data;
		} catch(e) {
			rejectWithValue({
				message: e.data.message || e.message
			})
		}
	}
);

export const getAyahById = createAsyncThunk("mahasiswa/getAyahById", 
	async (id, { rejectWithValue }) => {
		try {
			const token = await localStorage.getItem('auth_token');
			jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
			const response = await jwtAuthAxios.get(`v1/biodata-ortu/${id}`);
			return response.data;
		} catch(e) {
			rejectWithValue({
				message: e.data.message || e.message
			})
		}
	}
);

export const getIbuById = createAsyncThunk("mahasiswa/getIbuById", 
	async (id, { rejectWithValue }) => {
		try {
			const token = await localStorage.getItem('auth_token');
			jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
			const response = await jwtAuthAxios.get(`v1/biodata-ortu/${id}`);
			return response.data;
		} catch(e) {
			rejectWithValue({
				message: e.data.message || e.message
			})
		}
	}
);

export const deleteMahasiswa = createAsyncThunk("mahasiswa/deleteMahasiswa", 
	async (id, { rejectWithValue }) => {
		try {
			const token = await localStorage.getItem('auth_token');
			jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
			await jwtAuthAxios.delete(`v1/biodata-mahasiswa/${id}`);
			return id;
		} catch(e) {
			rejectWithValue({
				message: e.data.message || e.message
			})
		}
	}
);

export const addMahasiswa = createAsyncThunk("mahasiswa/addMahasiswa",
	async (payload, { rejectWithValue }) => {
		try {
			const token = await localStorage.getItem('auth_token');
			jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
			const response = await jwtAuthAxios.post('v1/biodata-mahasiswa', payload);
			return response.data;
		} catch(e) {
			rejectWithValue({
				message: e.data.message || e.message
			})
		}
	}
);

export const updateMahasiswa = createAsyncThunk("mahasiswa/updateMahasiswa", 
	async (payload, { rejectWithValue }) => {
		try {
			const token = await localStorage.getItem('auth_token');
			jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
			const response = await jwtAuthAxios.put('v1/biodata-mahasiswa', payload);
			return response.data;
		} catch(e) {
			rejectWithValue({
				message: e.data.message || e.message
			})
		}
	}
);

export const downloadTemplateExcel = createAsyncThunk("mahasiswa/downloadTemplateExcel",
	async (payload, { rejectWithValue }) => {
		try {
			const workbook = await generateTemplate(payload);

			// Save to blob
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { 
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
			});
      const url = URL.createObjectURL(blob);

      // Create a temporary link element
      const link = document.createElement('a');
      link.href = url;
      link.download = 'mahasiswa_data.xlsx';

      // Trigger a click on the link to initiate the download
      link.click();

      // Cleanup
      URL.revokeObjectURL(url);
		} catch(e) {
			rejectWithValue({
				message: e.data.message || e.message
			})
		}
	}
);

const biodata = {
	namaLengkap: '',
	nik: '',
	jenisKelamin: '',
	tempatLahir: '',
	tanggalLahir: dayjs('1980-01-01', DATE_FORMAT_DD_MM_YYYY_T_HH_mm_ss),
	agama: '',
	kewarganegaraan: '',
	alamat: '',
	rt: '',
	rw: '',
	kelurahan: '',
	kecamatan: '',
	kabupaten: '',
	provinsi: '',
	kodepos: '',
	teleponRumah: '',
	email: '',
	handphone: ''
};

const mahasiswaBiodata = {
	nisn: '',
};

const ortuBiodata = {
	npwp: '',
	jenjangPendidikan: '',
	pekerjaan: '',
	penghasilan: ''
}

const initialState = {
	allMahasiswa: [],
	listMahasiswa: [],
	single: {
		biodata_mahasiswa: {
			...biodata,
			...mahasiswaBiodata,
		},
		biodata_ayah: {
			...biodata,
			...ortuBiodata,
		},
		biodata_ibu: {
			...biodata,
			...ortuBiodata
		}
	},
	steps: {
		current: 0
	},
	status: {
		loading: false
	}
}

const mahasiswaSlice = createSlice({
	name: "mahasiswa",
	initialState,
	reducers: {
    onRefreshForm: (state) => {
			state.single = initialState.single
      state.status = initialState.status;
    },
    onRefreshStatus: (state) => {
      state.status = initialState.status;
    },
		onRefreshSteps: (state) => {
			state.steps.current = 0;
		},
    onChangeData: (state, action) => ({
      ...state, 
      ...action.payload
    }),
		nextStep: (state) => {
			state.steps.current++;
		},
		prevStep: (state) => {
			state.steps.current--;
		},
	},
	extraReducers: {
		[getAllMahasiswa.pending]: (state, action) => {
			state.status.loading = true;
		},
		[getAllMahasiswa.fulfilled]: (state, action) => {
			state.allMahasiswa = action.payload;
			state.status.loading = false;
		},
		[getAllMahasiswa.rejected]: (state, action) => {
			state.status.loading = false;
		},
		[getListMahasiswa.pending]: (state, action) => {
			state.status.loading = true;
		},
		[getListMahasiswa.fulfilled]: (state, action) => {
			state.listMahasiswa = action.payload;
			state.status.loading = false;
		},
		[getListMahasiswa.rejected]: (state, action) => {
			state.status.loading = false;
		},
		[getMahasiswaById.pending]: (state, action) => {
			state.status.loading = true;
		},
		[getMahasiswaById.fulfilled]: (state, action) => {
			state.single.biodata_mahasiswa = action.payload;
			state.status.loading = false;
		},
		[getMahasiswaById.rejected]: (state, action) => {
			state.status.loading = false;
		},

		[getAyahById.pending]: (state, action) => {
			state.status.loading = true;
		},
		[getAyahById.fulfilled]: (state, action) => {
			state.single.biodata_ayah = action.payload;
			state.status.loading = false;
		},
		[getAyahById.rejected]: (state, action) => {
			state.status.loading = false;
		},

		[getIbuById.pending]: (state, action) => {
			state.status.loading = true;
		},
		[getIbuById.fulfilled]: (state, action) => {
			state.single.biodata_ibu = action.payload;
			state.status.loading = false;
		},
		[getIbuById.rejected]: (state, action) => {
			state.status.loading = false;
		},

		[addMahasiswa.pending]: (state, action) => {
			state.status.loading = true;
		},
		[addMahasiswa.fulfilled]: (state, action) => {
			state.status.loading = false;
		},
		[addMahasiswa.rejected]: (state, action) => {
			state.status.loading = false;
		},

		[deleteMahasiswa.pending]: (state, action) => {
			state.status.loading = true;
		},
		[deleteMahasiswa.fulfilled]: (state, action) => {
			state.status.loading = false;
		},
		[deleteMahasiswa.rejected]: (state, action) => {
			state.status.loading = false;
		},

		[updateMahasiswa.pending]: (state, action) => {
			state.status.loading = true;
		},
		[updateMahasiswa.fulfilled]: (state, action) => {
			state.status.loading = false;
		},
		[updateMahasiswa.rejected]: (state, action) => {
			state.status.loading = false;
		},

		[downloadTemplateExcel.pending]: (state, action) => {
			state.status.loading = true;
		},
		[downloadTemplateExcel.fulfilled]: (state, action) => {
			state.status.loading = false;
		},
		[downloadTemplateExcel.rejected]: (state, action) => {
			state.status.loading = false;
		},
	}
})

export const { 
	onRefreshForm,
	onRefreshStatus,
	onChangeData,
	nextStep,
	prevStep,
	onRefreshSteps
} = mahasiswaSlice.actions;

export const selectMahasiswa = (state) => state.mahasiswa;

export default mahasiswaSlice.reducer;