import ExcelJS from 'exceljs';

const generateTemplate = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      const workbook = new ExcelJS.Workbook();

      payload.forEach((section) => {
        const { name, title, headers } = section;

        const sheet = workbook.addWorksheet(name);

        // Set header row
        sheet.addRow([title]);
        sheet.addRow(headers);
      });

      resolve(workbook);
    } catch (error) {
      reject(error);
    }
  });
};

export default generateTemplate;