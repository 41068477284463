import jwtAuthAxios from "../../../../services/jwtAuth";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getAll = createAsyncThunk("makul/get", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/peyetaraan/mata-kuliah`);
    return response.data;
});

export const getByUserId = createAsyncThunk("makul/getId", async(id) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/peyetaraan/mata-kuliah/user/${id}`);
    return response.data;
});


export const addMakul = createAsyncThunk("makul/save",
    async (payload, { rejectWithValue }) => {
        try {
            const token = await localStorage.getItem('auth_token');
            jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            const response = await jwtAuthAxios.post('v1/peyetaraan/mata-kuliah', payload);
            return response.data;
        } catch(e) {
            rejectWithValue({
                message: e.data.message || e.message
            })
        }
    }
);
export const updateMakul = createAsyncThunk("makul/update",
    async (payload, { rejectWithValue }) => {
        try {
            const token = await localStorage.getItem('auth_token');
            jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            const response = await jwtAuthAxios.put('v1/peyetaraan/mata-kuliah', payload);
            return response.data;
        } catch(e) {
            rejectWithValue({
                message: e.data.message || e.message
            })
        }
    }
);
export const deleteMakul = createAsyncThunk("makul/delete",
    async (id, { rejectWithValue }) => {
        try {
            const token = await localStorage.getItem('auth_token');
            jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            const response = await jwtAuthAxios.delete(`v1/peyetaraan/mata-kuliah/${id}`);
            return response.data;
        } catch(e) {
            rejectWithValue({
                message: e.data.message || e.message
            })
        }
    }
);

const initialState = {
    loadingMataKuliah : false,
    responseListMataKuliah : [],
    response : {}
}

const mataKuliahSlice = createSlice({
    name:"mataKuliahSlice",
    initialState: initialState,
    extraReducers: {
        [getAll.pending] : (state, action) => {
            state.loadingMataKuliah = true;
        },
        [getAll.fulfilled] : (state, action) => {
            state.responseListMataKuliah = action.payload;
            state.loadingMataKuliah = false;
        },
        [getAll.pending] : (state, action) => {
            state.loadingMataKuliah = true;
        },
        [getByUserId.pending] : (state, action) => {
            state.loadingMataKuliah = true;
        },
        [getByUserId.fulfilled] : (state, action) => {
            state.responseListMataKuliah = action.payload;
            state.loadingMataKuliah = false;
        },
        [getByUserId.pending] : (state, action) => {
            state.loadingMataKuliah = true;
        },
        [addMakul.pending] : (state, action) => {
            state.loadingMataKuliah = true;
        },
        [addMakul.fulfilled] : (state, action) => {
            state.response = action.payload;
            state.loadingMataKuliah = false;
        },
        [addMakul.pending] : (state, action) => {
            state.loadingMataKuliah = true;
        },
        [updateMakul.pending] : (state, action) => {
            state.loadingMataKuliah = true;
        },
        [updateMakul.fulfilled] : (state, action) => {
            state.response = action.payload;
            state.loadingMataKuliah = false;
        },
        [updateMakul.pending] : (state, action) => {
            state.loadingMataKuliah = true;
        },
        [deleteMakul.pending] : (state, action) => {
            state.loadingMataKuliah = true;
        },
        [deleteMakul.fulfilled] : (state, action) => {
            state.response = action.payload;
            state.loadingMataKuliah = false;
        },
        [deleteMakul.pending] : (state, action) => {
            state.loadingMataKuliah = true;
        },
    }
})

export const mataKuliahSelector = (state) => state.mataKuliah;
export default mataKuliahSlice.reducer;