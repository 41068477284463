import jwtAuthAxios from "../../services/jwtAuth";
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

export const getPendaftarByUser = createAsyncThunk("pendaftaran/getByUser", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/pendaftar/get-user`);
    return response.data;
});

const initialState = {
    loading : false,
    dataList : [],
    dataPendaftar : [],
    selectedData:{}
}

const pendaftaranSlice = createSlice({
    name:"pendaftaranSlice",
    initialState: initialState,
    extraReducers: {
        [getPendaftarByUser.pending] : (state, action) => {
            state.loading = true;
        },
        [getPendaftarByUser.fulfilled] : (state, action) => {
            state.dataPendaftar = action.payload;
            state.selectedData = action.payload;
            state.loading = false;
        },
        [getPendaftarByUser.pending] : (state, action) => {
            state.loading = true;
        },
    }
})

export const pendaftaranSelector = (state) => state.pendaftaran;
export default pendaftaranSlice.reducer;