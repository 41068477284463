import jwtAuthAxios from "../../../services/jwtAuth";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


export const findByPeriode = createAsyncThunk("reportDaftarUlang/findByPeriode", async (val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.post(`v1/report/registrasi/ulang`, val);
    return response.data;
});

export const findByPeriodePendaftaran = createAsyncThunk("reportDaftarUlang/findByPeriodePendaftaran", async (val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.post(`v1/report/pendaftaran`, val);
    return response.data;
});

const initialState = {
    dataPeriode: [],
    dataPendaftaran: [],
    dataPeriodeLoading: false,
    findByPeriodePendaftaran: false,
}

const reportDaftarUlangSlice = createSlice({
    name: "reportDaftarUlang",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(findByPeriode.pending, (state, action) => {
                state.dataPeriodeLoading = true
            })
            .addCase(findByPeriode.fulfilled, (state, action) => {
                state.dataPeriode = action.payload
                state.dataPeriodeLoading = false
            })
            .addCase(findByPeriode.rejected, (state, action) => {
                state.dataPeriodeLoading = false
                state.editPayError = action.error
            })
        builder
            .addCase(findByPeriodePendaftaran.pending, (state, action) => {
                state.dataPendaftaranLoading = true
            })
            .addCase(findByPeriodePendaftaran.fulfilled, (state, action) => {
                state.dataPendaftaran = action.payload
                state.dataPendaftaranLoading = false
            })
            .addCase(findByPeriodePendaftaran.rejected, (state, action) => {
                state.dataPendaftaranLoading = false
                state.editPayError = action.error
            })
    }
});

export const reportDaftarUlangSelectors = (state) => state.reportDaftarUlang;
export default reportDaftarUlangSlice.reducer;
