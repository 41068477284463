import jwtAuthAxios from "../../services/jwtAuth";
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

export const getPeriode = createAsyncThunk("periode/getAll", 
async({page, pageSize, search}) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const searchParams = search === undefined ? "" : search;
    let url;
    if(page){
        url = `v1/periode/pageable?page=${page}&size=${pageSize}&search=${searchParams}`
    }else{
        url = `v1/periode`
    }
    const response = await jwtAuthAxios.get(url);
    return response.data;
});

export const getPeriodeById = createAsyncThunk("periode/getById", async(id) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/periode/${id}`);
    console.log("response : ",JSON.stringify(response.data))
    return response.data;
});

export const deletePeriode = createAsyncThunk("periode/delete", async(id) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    await jwtAuthAxios.delete(`v1/periode/${id}`);
    return id;
});

export const savePeriode = createAsyncThunk("periode/save", async(payload) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.post('v1/periode',payload);
    return response.data;
});
export const updatePeriode = createAsyncThunk("periode/update", async(request) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    console.log("request : ",request)
    const response = await jwtAuthAxios.put('v1/periode',request);
    return response.data;
});

const periodeEntity = createEntityAdapter({
    selectId: (periode) => periode.id
});

const initialState = {
    dataAllPeriode: []
}


const periodeSlice = createSlice({
    name:"periode",
    initialState: initialState,
    extraReducers: {
        [getPeriode.pending] : (state, action) => {
            state.loading = true;
        },
        [getPeriode.fulfilled] : (state, action) => {
            state.dataAllPeriode = action.payload;
            state.loading = false;
        },
        [getPeriode.rejected] : (state, action) => {
            state.loading = false;
        },
        [getPeriodeById.fulfilled] : (state, action) => {

        },
        [savePeriode.fulfilled] : (state, action) => {
        },
        [deletePeriode.fulfilled] : (state, action) => {
        },
        [updatePeriode.fulfilled] : (state, action) => {
        }
    }
})

export const periodeSelectors = (state => state.periode);
export default periodeSlice.reducer;