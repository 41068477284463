import jwtAuthAxios from "../../../services/jwtAuth";
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

export const updatePayment = createAsyncThunk("paymentCicilan/updatePayment", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    //const response = await jwtAuthAxios.post(`v1/payment-detail/trx/${val}`);
    const response = await jwtAuthAxios.post('v1/payment-cicilan/update',val);
    return response.data;
});

const initialState = {
    loading : false,
    response : {}
}

const paymentCicilanSlice = createSlice({
    name:"paymentCicilanSlice",
    initialState: initialState,
    extraReducers: {
        [updatePayment.pending] : (state, action) => {
            state.loading = true;
        },
        [updatePayment.fulfilled] : (state, action) => {
            state.response = action.payload;
            state.loading = false;
        },
        [updatePayment.pending] : (state, action) => {
            state.loading = true;
        },
    }
})

export const paymentCicilanSelector = (state) => state.paymentCicilan;
export default paymentCicilanSlice.reducer;