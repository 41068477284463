import jwtAuthAxios from "../../../services/jwtAuth";
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

export const getDashboardDaftar = createAsyncThunk("dashboard/daftar", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/dashboard/jalur`);
    return response.data;
});

const initialState = {
    loadingDashDaftar : false,
    responseDashDaftar : {}
}

const dashDaftarSlice = createSlice({
    name:"dashDaftarSlice",
    initialState: initialState,
    extraReducers: {
        [getDashboardDaftar.pending] : (state, action) => {
            state.loadingDashDaftar = true;
        },
        [getDashboardDaftar.fulfilled] : (state, action) => {
            state.responseDashDaftar = action.payload;
            state.loadingDashDaftar = false;
        },
        [getDashboardDaftar.pending] : (state, action) => {
            state.loadingDashDaftar = true;
        },
    }
})

export const dashDaftarSelector = (state) => state.dashDaftar;
export default dashDaftarSlice.reducer;