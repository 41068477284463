import jwtAuthAxios from "../../services/jwtAuth";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getKurikulumAll = createAsyncThunk("kurikulum/getAll", async({page = 1, size = 3}) => {
    try{
        let token = await localStorage.getItem('auth_token');
        jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        const response = await jwtAuthAxios.get(`v1/kurikulum/pageable?page=${page}&size=${size}`)
        return response.data.data.result
    } catch(response){
        throw response
    }
});

export const getAllNoPage = createAsyncThunk("kurikulum/getAllNoPage", async() => {
    try{
        let token = await localStorage.getItem('auth_token');
        jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        const response = await jwtAuthAxios.get(`v1/kurikulum`)
        return response.data
    } catch(response){
        throw response
    }
});


export const getByKurikulumSemester = createAsyncThunk("kurikulum/getByKurikulumSemester", 
async({ kurikulumId, semesterId }, thunkAPI) => {
    try{
        let token = await localStorage.getItem('auth_token');
        jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        const url = `v1/kurikulum/mk/filter?kurikulumId=${kurikulumId}&semesterId=${semesterId}`
        const response = await jwtAuthAxios.get(url)
        return response.data
    } catch(response){
        throw response
    }
});



const initialState = {
    loading: false,
    dataAllKurikulum: [],
    dataAllNoPage:[],
    dataKurikulumSemester: []
}

const kurikulumSlice = createSlice({
    name:"kurikulum",
    initialState: initialState,
    extraReducers: (builder) => {
    builder
        .addCase(getKurikulumAll.pending, (state) => {
            state.loading = true;
        })
        .addCase(getKurikulumAll.fulfilled, (state, action) => {
            state.dataAllKurikulum = action.payload;
            state.loading = false;
        })
        .addCase(getKurikulumAll.rejected, (state, action) => {
            state.loading = false;
            console.error("Error during getMatkul:", action.error);
        })
    builder
        .addCase(getAllNoPage.pending, (state) => {
            state.loading = true;
        })
        .addCase(getAllNoPage.fulfilled, (state, action) => {
            state.dataAllNoPage = action.payload;
            state.loading = false;
        })
        .addCase(getAllNoPage.rejected, (state, action) => {
            state.loading = false;
            console.error("Error during getMatkul:", action.error);
        })
    builder
        .addCase(getByKurikulumSemester.pending, (state) => {
            state.loading = true;
        })
        .addCase(getByKurikulumSemester.fulfilled, (state, action) => {
            state.dataKurikulumSemester = action.payload;
            state.loading = false;
        })
        .addCase(getByKurikulumSemester.rejected, (state, action) => {
            state.loading = false;
            console.error("Error during getMatkul:", action.error);
        })
        
    }
})
export const kurikulumSelector = (state) => state.kurikulum;
export default kurikulumSlice.reducer;