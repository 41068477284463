import jwtAuthAxios from "../../services/jwtAuth";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


export const getUserManagement = createAsyncThunk("userManagement/getAll", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get('/v1/users');
    return response.data;

});

export const addUserManagement = createAsyncThunk("userManagement/save",
    async (payload, { rejectWithValue }) => {
        try {
            const token = await localStorage.getItem('auth_token');
            jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            const response = await jwtAuthAxios.post('v1/users', payload);
            return response.data;
        } catch(e) {
            rejectWithValue({
                message: e.data.message || e.message
            })
        }
    }
);
export const updateUserManagement = createAsyncThunk("userManagement/update",
    async (payload, { rejectWithValue }) => {
        try {
            const token = await localStorage.getItem('auth_token');
            jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            const response = await jwtAuthAxios.put('v1/users', payload);
            return response.data;
        } catch(e) {
            rejectWithValue({
                message: e.data.message || e.message
            })
        }
    }
);
export const deleteUserManagement = createAsyncThunk("userManagement/delete",
    async (id, { rejectWithValue }) => {
        try {
            const token = await localStorage.getItem('auth_token');
            jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            const response = await jwtAuthAxios.delete(`v1/users/${id}`);
            return response.data;
        } catch(e) {
            rejectWithValue({
                message: e.data.message || e.message
            })
        }
    }
);

const initialState = {
    loading: false,
    dataList: [],
    selectedData:{}
}


const userManagementSlice = createSlice({
    name:"userManagement",
    initialState: initialState,
    reducers:{
        /*        addCommonFaq:(state, action) => {
                    const existingIndex = state.dataCommonFaq.findIndex(item => item.questionId === action.payload.questionId);
                    if (existingIndex !== -1) {
                        state.dataCommonFaq[existingIndex] = action.payload;
                    } else {
                        state.dataCommonFaq.push(action.payload);
                    }
                },*/
        clearUserManagement:(state) => {
            state.dataList= []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserManagement.pending, (state) => {
                state.loading = true;
            })
            .addCase(getUserManagement.fulfilled, (state, action) => {
                state.dataList = action.payload;
                state.loading = false;
            })
            .addCase(getUserManagement.rejected, (state, action) => {
                state.loading = false;
                console.error("Error during get data:", action.error);
            })
            .addCase(addUserManagement.pending, (state) => {
                state.loading = true;
            })
            .addCase(addUserManagement.fulfilled, (state, action) => {
                state.selectedData = action.payload;
                state.loading = false;
            })
            .addCase(addUserManagement.rejected, (state, action) => {
                state.loading = false;
                console.error("Error during get data:", action.error);
            })
            .addCase(updateUserManagement.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateUserManagement.fulfilled, (state, action) => {
                state.selectedData = action.payload;
                state.loading = false;
            })
            .addCase(updateUserManagement.rejected, (state, action) => {
                state.loading = false;
                console.error("Error during get data:", action.error);
            })
            .addCase(deleteUserManagement.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteUserManagement.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteUserManagement.rejected, (state, action) => {
                state.loading = false;
                console.error("Error during get data:", action.error);
            })
    }
})
export const userManagementSelector = (state) => state.userManagement;
export const {
    clearUserManagement
} = userManagementSlice.actions;

export default userManagementSlice.reducer;