import jwtAuthAxios from "../../services/jwtAuth";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { generateTemplate } from "configs/utils/Excel";

export const getAllDosen = createAsyncThunk("dosen/getAllDosen",
	async (_, { rejectWithValue }) => {
		try {
			const token = await localStorage.getItem('auth_token');
			jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
			const response = await jwtAuthAxios.get('v1/dosen');
			return response.data;
		} catch (e) {
			rejectWithValue({
				message: e.data.message || e.message
			})
		}
	}
);

export const getDosenById = createAsyncThunk("dosen/getDosenById",
	async (id, { rejectWithValue }) => {
		try {
			const token = await localStorage.getItem('auth_token');
			jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
			const response = await jwtAuthAxios.get(`v1/dosen/${id}`);
			return response.data;
		} catch(e) {
			rejectWithValue({
				message: e.data.message || e.message
			})
		}
	}
);

export const deleteDosen = createAsyncThunk("dosen/deleteDosen",
	async (id, { rejectWithValue }) => {
		try {
			const token = await localStorage.getItem('auth_token');
			jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
			await jwtAuthAxios.delete(`v1/dosen/${id}`);
			return id;
		} catch(e) {
			rejectWithValue({
				message: e.data.message || e.message
			})
		}
	}
);

export const addDosen = createAsyncThunk("dosen/addDosen",
	async (payload, { rejectWithValue }) => {
		try {
			const token = await localStorage.getItem('auth_token');
			jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
			const response = await jwtAuthAxios.post('v1/dosen', payload);
			return response.data;
		} catch(e) {
			rejectWithValue({
				message: e.data.message || e.message
			})
		}
	}
);

export const updateDosen = createAsyncThunk("dosen/updateDosen",
	async (payload, { rejectWithValue }) => {
		try {
			const token = await localStorage.getItem('auth_token');
			jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
			const response = await jwtAuthAxios.put('v1/dosen', payload);
			return response.data;
		} catch(e) {
			rejectWithValue({
				message: e.data.message || e.message
			})
		}
	}
);

export const getPenugasan = createAsyncThunk("dosen/getPenugasan",
	async (id, { rejectWithValue }) => {
		try {
			const token = await localStorage.getItem('auth_token');
			jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
			const response = await jwtAuthAxios.get(`v1/dosen/penugasan/${id}`);
			return response.data;
		} catch (e) {
			rejectWithValue({
				message: e.data.message || e.message
			})
		}
	}
);

export const getAktivitasMengajar = createAsyncThunk("dosen/getAktivitasMengajar",
	async (id, { rejectWithValue }) => {
		try {
			const token = await localStorage.getItem('auth_token');
			jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
			const response = await jwtAuthAxios.get(`v1/dosen/riwayat/mengajar/filter/${id}`);
			return response.data;
		} catch (e) {
			rejectWithValue({
				message: e.data.message || e.message
			})
		}
	}
);

export const getRiwayatFungsional = createAsyncThunk("dosen/getRiwayatFungsional",
	async (id, { rejectWithValue }) => {
		try {
			const token = await localStorage.getItem('auth_token');
			jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
			const response = await jwtAuthAxios.get(`v1/dosen/riwayat/fungsional/filter/${id}`);
			return response.data;
		} catch (e) {
			rejectWithValue({
				message: e.data.message || e.message
			})
		}
	}
);

export const getRiwayatPangkat = createAsyncThunk("dosen/getRiwayatPangkat",
	async (id, { rejectWithValue }) => {
		try {
			const token = await localStorage.getItem('auth_token');
			jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
			const response = await jwtAuthAxios.get(`v1/dosen/riwayat/pangkat/filter/${id}`);
			return response.data;
		} catch (e) {
			rejectWithValue({
				message: e.data.message || e.message
			})
		}
	}
);

export const getRiwayatPendidikan = createAsyncThunk("dosen/getRiwayatPendidikan",
	async (id, { rejectWithValue }) => {
		try {
			const token = await localStorage.getItem('auth_token');
			jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
			const response = await jwtAuthAxios.get(`v1/dosen/riwayat/pendidikan/filter/${id}`);
			return response.data;
		} catch (e) {
			rejectWithValue({
				message: e.data.message || e.message
			})
		}
	}
);

export const getRiwayatSertifikasi = createAsyncThunk("dosen/getRiwayatSertifikasi",
	async (id, { rejectWithValue }) => {
		try {
			const token = await localStorage.getItem('auth_token');
			jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
			const response = await jwtAuthAxios.get(`v1/dosen/riwayat/sertifikasi/filter/${id}`);
			return response.data;
		} catch (e) {
			rejectWithValue({
				message: e.data.message || e.message
			})
		}
	}
);

export const getRiwayatPenelitian = createAsyncThunk("dosen/getRiwayatPenelitian",
	async (id, { rejectWithValue }) => {
		try {
			const token = await localStorage.getItem('auth_token');
			jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
			const response = await jwtAuthAxios.get(`v1/dosen/riwayat/penelitian/filter/${id}`);
			return response.data;
		} catch (e) {
			rejectWithValue({
				message: e.data.message || e.message
			})
		}
	}
);

export const getRiwayatPembimbing = createAsyncThunk("dosen/getRiwayatPembimbing",
	async (id, { rejectWithValue }) => {
		try {
			const token = await localStorage.getItem('auth_token');
			jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
			const response = await jwtAuthAxios.get(`v1/dosen/riwayat/pembimbing/filter/${id}`);
			return response.data;
		} catch (e) {
			rejectWithValue({
				message: e.data.message || e.message
			})
		}
	}
);

export const getRiwayatMengajar = createAsyncThunk("dosen/getRiwayatMengajar",
	async (id, { rejectWithValue }) => {
		try {
			const token = await localStorage.getItem('auth_token');
			jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
			const response = await jwtAuthAxios.get(`v1/dosen/riwayat/mengajar/filter/${id}`);
			return response.data;
		} catch (e) {
			rejectWithValue({
				message: e.data.message || e.message
			})
		}
	}
);

export const getRiwayatPenguji = createAsyncThunk("dosen/getRiwayatPenguji",
	async (id, { rejectWithValue }) => {
		try {
			const token = await localStorage.getItem('auth_token');
			jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
			const response = await jwtAuthAxios.get(`v1/dosen/riwayat/penguji/filter/${id}`);
			return response.data;
		} catch (e) {
			rejectWithValue({
				message: e.data.message || e.message
			})
		}
	}
);

export const downloadTemplateExcel = createAsyncThunk("dosen/downloadTemplateExcel",
	async (payload, { rejectWithValue }) => {
		try {
			const workbook = await generateTemplate(payload);

			// Save to blob
			const buffer = await workbook.xlsx.writeBuffer();
			const blob = new Blob([buffer], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
			});
			const url = URL.createObjectURL(blob);

			// Create a temporary link element
			const link = document.createElement('a');
			link.href = url;
			link.download = 'dosen_data.xlsx';

			// Trigger a click on the link to initiate the download
			link.click();

			// Cleanup
			URL.revokeObjectURL(url);
		} catch(e) {
			rejectWithValue({
				message: e.data.message || e.message
			})
		}
	}
);

const initialState = {
	allDosen: [],
	single: {
		id: '',
		id_feeder: null,
		nama_dosen: '',
		kode_dosen: '',
		tempat_lahir: '',
		tanggal_lahir: '',
		jenis_kelamin: '',
		id_agama: '',
		nama_agama: '',
		id_status_aktif: '',
		nama_status_aktif: '',
		nidn: '',
		nama_ibu_kandung: '',
		nik: '',
		nip: '',
		npwp: '',
		id_jenis_sdm: '',
		nama_jenis_sdm: '',
		no_sk_cpns: '',
		tanggal_sk_cpns: '',
		no_sk_pengangkatan: '',
		mulai_sk_pengangkatan: '',
		id_lembaga_pengangkatan: '',
		nama_lembaga_pengangkatan: '',
		id_pangkat_golongan: '',
		nama_pangkat_golongan: '',
		id_sumber_gaji: '',
		nama_sumber_gaji: '',
		jalan: '',
		dusun: '',
		rt: '',
		rw: '',
		ds_kel: '',
		kode_pos: '',
		id_wilayah: '',
		nama_wilayah: '',
		telepon: '',
		handphone: '',
		email: '',
		status_pernikahan: '',
		nama_suami_istri: '',
		nip_suami_istri: '',
		tanggal_mulai_pns: '',
		id_pekerjaan_suami_istri: '',
		nama_pekerjaan_suami_istri: '',
		created_by: '',
		created_time: '',
		updated_by: '',
		updated_time: ''
	},
	penugasan: [],
	activitasMengajar: [],
	riwayatFungsional: [],
	riwayatPangkat: [],
	riwayatPendidikan: [],
	riwayatSertifikasi: [],
	riwayatPenelitian: [],
	riwayatPembimbing: [],
	riwayatMengajar: [],
	riwayatPenguji: [],
	steps: {
		current: 0
	},
	tabs: {
		current: 0
	},
	status: {
		loading: false
	}
}

const dosenSlice = createSlice({
	name: "dosen",
	initialState,
	reducers: {
		onRefreshForm: (state) => {
			state.single = initialState.single
			state.status = initialState.status;
		},
		onRefreshStatus: (state) => {
			state.status = initialState.status;
		},
		onRefreshSteps: (state) => {
			state.steps.current = 0;
		},
		onChangeData: (state, action) => ({
			...state,
			...action.payload
		}),
		nextStep: (state) => {
			state.steps.current++;
		},
		prevStep: (state) => {
			state.steps.current--;
		},
		onChangeTabs: (state, action) => ({
			...state,
			...state.tabs = action.payload
		})
	},
	extraReducers: {
		[getAllDosen.pending]: (state, action) => {
			state.status.loading = true;
		},
		[getAllDosen.fulfilled]: (state, action) => {
			state.allDosen = action.payload;
			state.status.loading = false;
		},
		[getAllDosen.rejected]: (state, action) => {
			state.status.loading = false;
		},

		[getDosenById.pending]: (state, action) => {
			state.status.loading = true;
		},
		[getDosenById.fulfilled]: (state, action) => {
			state.single = action.payload;
			state.status.loading = false;
		},
		[getDosenById.rejected]: (state, action) => {
			state.status.loading = false;
		},

		[addDosen.pending]: (state, action) => {
			state.status.loading = true;
		},
		[addDosen.fulfilled]: (state, action) => {
			state.status.loading = false;
		},
		[addDosen.rejected]: (state, action) => {
			state.status.loading = false;
		},

		[deleteDosen.pending]: (state, action) => {
			state.status.loading = true;
		},
		[deleteDosen.fulfilled]: (state, action) => {
			state.status.loading = false;
		},
		[deleteDosen.rejected]: (state, action) => {
			state.status.loading = false;
		},

		[updateDosen.pending]: (state, action) => {
			state.status.loading = true;
		},
		[updateDosen.fulfilled]: (state, action) => {
			state.status.loading = false;
		},
		[updateDosen.rejected]: (state, action) => {
			state.status.loading = false;
		},

		[getPenugasan.pending]: (state, action) => {
			state.status.loading = true;
		},
		[getPenugasan.fulfilled]: (state, action) => {
			state.riwayatFungsional = action.payload;
			state.status.loading = false;
		},
		[getPenugasan.rejected]: (state, action) => {
			state.status.loading = false;
		},

		[getAktivitasMengajar.pending]: (state, action) => {
			state.status.loading = true;
		},
		[getAktivitasMengajar.fulfilled]: (state, action) => {
			state.riwayatFungsional = action.payload;
			state.status.loading = false;
		},
		[getAktivitasMengajar.rejected]: (state, action) => {
			state.status.loading = false;
		},

		[getRiwayatFungsional.pending]: (state, action) => {
			state.status.loading = true;
		},
		[getRiwayatFungsional.fulfilled]: (state, action) => {
			state.riwayatFungsional = action.payload;
			state.status.loading = false;
		},
		[getRiwayatFungsional.rejected]: (state, action) => {
			state.status.loading = false;
		},

		[getRiwayatMengajar.pending]: (state, action) => {
			state.status.loading = true;
		},
		[getRiwayatMengajar.fulfilled]: (state, action) => {
			state.riwayatMengajar = action.payload;
			state.status.loading = false;
		},
		[getRiwayatMengajar.rejected]: (state, action) => {
			state.status.loading = false;
		},

		[getRiwayatPangkat.pending]: (state, action) => {
			state.status.loading = true;
		},
		[getRiwayatPangkat.fulfilled]: (state, action) => {
			state.riwayatPangkat = action.payload;
			state.status.loading = false;
		},
		[getRiwayatPangkat.rejected]: (state, action) => {
			state.status.loading = false;
		},

		[getRiwayatPembimbing.pending]: (state, action) => {
			state.status.loading = true;
		},
		[getRiwayatPembimbing.fulfilled]: (state, action) => {
			state.riwayatPembimbing = action.payload;
			state.status.loading = false;
		},
		[getRiwayatPembimbing.rejected]: (state, action) => {
			state.status.loading = false;
		},

		[getRiwayatPendidikan.pending]: (state, action) => {
			state.status.loading = true;
		},
		[getRiwayatPendidikan.fulfilled]: (state, action) => {
			state.riwayatPendidikan = action.payload;
			state.status.loading = false;
		},
		[getRiwayatPendidikan.rejected]: (state, action) => {
			state.status.loading = false;
		},

		[getRiwayatPenelitian.pending]: (state, action) => {
			state.status.loading = true;
		},
		[getRiwayatPenelitian.fulfilled]: (state, action) => {
			state.riwayatPenelitian = action.payload;
			state.status.loading = false;
		},
		[getRiwayatPenelitian.rejected]: (state, action) => {
			state.status.loading = false;
		},

		[getRiwayatSertifikasi.pending]: (state, action) => {
			state.status.loading = true;
		},
		[getRiwayatSertifikasi.fulfilled]: (state, action) => {
			state.riwayatSertifikasi = action.payload;
			state.status.loading = false;
		},
		[getRiwayatSertifikasi.rejected]: (state, action) => {
			state.status.loading = false;
		},

		[getRiwayatPenguji.pending]: (state, action) => {
			state.status.loading = true;
		},
		[getRiwayatPenguji.fulfilled]: (state, action) => {
			state.riwayatPembimbing = action.payload;
			state.status.loading = false;
		},
		[getRiwayatPenguji.rejected]: (state, action) => {
			state.status.loading = false;
		},

		[downloadTemplateExcel.pending]: (state, action) => {
			state.status.loading = true;
		},
		[downloadTemplateExcel.fulfilled]: (state, action) => {
			state.status.loading = false;
		},
		[downloadTemplateExcel.rejected]: (state, action) => {
			state.status.loading = false;
		},
	}
})

export const {
	onRefreshForm,
	onRefreshStatus,
	onChangeData,
	onChangeTabs,
	nextStep,
	prevStep,
	onRefreshSteps
} = dosenSlice.actions;

export const selectDosen = (state) => state.dosen;

export default dosenSlice.reducer;