import jwtAuthAxios from "../../services/jwtAuth";
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    data_user_roles: [],
    data_user_roles_byId: [],
    save_user_roles: [],
    update_user_roles: [],
    delete_user_roles: [],
}

export const getUserRoles = createAsyncThunk("userroles/getAll", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get('v1/roles');
    return response.data;
});

export const getUserRolesById = createAsyncThunk("userroles/getById", async(id) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/users/${id}`);
    return response.data;
});

export const deleteUserRoles = createAsyncThunk("userroles/delete", async(id) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    await jwtAuthAxios.delete(`v1/users/${id}`);
    return id;
});

export const saveUserRoles = createAsyncThunk("userroles/save", async(payload) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.post('v1/users',payload);
    return response.data;
});
export const updateUserRoles = createAsyncThunk("userroles/update", async(request) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    //console.log("request : ",request)
    const response = await jwtAuthAxios.put('v1/users',request);
    return response.data;
});

const userrolesEntity = createEntityAdapter({
    selectId: (userroles) => userroles.id
});

const userrolesSlice = createSlice({
    name:"userroles",
    initialState,
    reducers:{

    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserRoles.pending,(state) => {
                state.loading = true
            })
            .addCase(getUserRoles.fulfilled, (state, action) => {
                state.data_user_roles = action.payload;
                state.loading = false;
            })
            .addCase(getUserRoles.rejected,(state) => {
                state.loading = false
            })
        builder
            .addCase(getUserRolesById.pending,(state) => {
                state.loading = true
            })
            .addCase(getUserRolesById.fulfilled, (state, action) => {
                state.data_user_roles_byId = action.payload;
                state.loading = false;
            })
            .addCase(getUserRolesById.rejected,(state) => {
                state.loading = false
            })
        builder
            .addCase(saveUserRoles.pending,(state) => {
                state.loading = true
            })
            .addCase(saveUserRoles.fulfilled, (state, action) => {
                state.save_user_roles = action.payload;
                state.loading = false;
            })
            .addCase(saveUserRoles.rejected,(state) => {
                state.loading = false
            })
        builder
            .addCase(updateUserRoles.pending,(state) => {
                state.loading = true
            })
            .addCase(updateUserRoles.fulfilled, (state, action) => {
                state.update_user_roles = action.payload;
                state.loading = false;
            })
            .addCase(updateUserRoles.rejected,(state) => {
                state.loading = false
            })
        builder
            .addCase(deleteUserRoles.pending,(state) => {
                state.loading = true
            })
            .addCase(deleteUserRoles.fulfilled, (state, action) => {
                state.delete_user_roles = action.payload;
                state.loading = false;
            })
            .addCase(deleteUserRoles.rejected,(state) => {
                state.loading = false
            })
    }
})

export const userrolesSelectors = userrolesEntity.getSelectors(state => state.userroles);
export default userrolesSlice.reducer;