import jwtAuthAxios from "../../services/jwtAuth";
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

export const getProdi = createAsyncThunk("prodi/getAll", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get('v1/program-studi');
    return response.data;
});
export const getProdiById = createAsyncThunk("prodi/getById", async(id) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/program-studi/${id}`);
    console.log("response : ",JSON.stringify(response.data))
    return response.data;
});

export const deleteProdi = createAsyncThunk("prodi/delete", async(id) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    await jwtAuthAxios.delete(`v1/program-studi/${id}`);
    return id;
});

export const saveProdi = createAsyncThunk("prodi/save", async({nama,kode,active}) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.post('v1/program-studi',{
        "kode": kode,
        "nama": nama,
        "active": active,
    });
    return response.data;
});
export const updateProdi = createAsyncThunk("prodi/update", async(request) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    console.log("request : ",request)
    const response = await jwtAuthAxios.put('v1/program-studi',request);
    return response.data;
});

export const getDetailProdi = createAsyncThunk("prodi/getDetail", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get('v1/program-studi/detail');
    return response.data;
});

const prodiEntity = createEntityAdapter({
    selectId: (prodi) => prodi.id
});

const initState = {
    listProdi: [],
    listDetailProdi: []
}

const prodiSlice = createSlice({
    name:"prodi",
    initState,
    initialState: prodiEntity.getInitialState,
    extraReducers: {
        [getProdi.pending] : (state, action) => {
            state.loading = true;
        },
        [getProdi.fulfilled] : (state, action) => {
            state.listProdi = action.payload;
            state.loading = false;
        },
        [getDetailProdi.pending] : (state, action) => {
            state.loading = true;
        },
        [getDetailProdi.fulfilled] : (state, action) => {
            state.listDetailProdi = action.payload;
            state.loading = false;
        },
        [getProdiById.fulfilled] : (state, action) => {
            prodiEntity.setOne(state,action.payload);
        },
        [saveProdi.fulfilled] : (state, action) => {
            prodiEntity.addOne(state,action.payload);
        },
        [deleteProdi.fulfilled] : (state, action) => {
            prodiEntity.removeOne(state,action.payload);
        },
        [updateProdi.fulfilled] : (state, action) => {
            prodiEntity.upsertOne(state,{id:action.payload.id, updates:action.payload});
        }
    }
})

export const prodiSelectors = (state) => state.prodi;

export default prodiSlice.reducer;