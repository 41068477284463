import jwtAuthAxios from "../../services/jwtAuth";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import jwtAxios from "axios";

export const getCommonFaq = createAsyncThunk("commonFaq/getAll", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get('/v1/common-faq');
    return response.data;
});
export const getCommonFaqPublic = createAsyncThunk("commonFaq/getAllPublic", async() => {
    const response = await jwtAxios.get('/pmb/public/api/v1/common-faq');
    return response.data;
});

export const addCommonFaq = createAsyncThunk("commonFaq/save",
    async (payload, { rejectWithValue }) => {
        try {
            const token = await localStorage.getItem('auth_token');
            jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            const response = await jwtAuthAxios.post('v1/common-faq', payload);
            return response.data;
        } catch(e) {
            rejectWithValue({
                message: e.data.message || e.message
            })
        }
    }
);
export const updateCommonFaq = createAsyncThunk("commonFaq/update",
    async (payload, { rejectWithValue }) => {
        try {
            const token = await localStorage.getItem('auth_token');
            jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            const response = await jwtAuthAxios.put('v1/common-faq', payload);
            return response.data;
        } catch(e) {
            rejectWithValue({
                message: e.data.message || e.message
            })
        }
    }
);
export const deleteCommonFaq = createAsyncThunk("commonFaq/delete",
    async (id, { rejectWithValue }) => {
        try {
            const token = await localStorage.getItem('auth_token');
            jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            const response = await jwtAuthAxios.delete(`v1/common-faq/${id}`);
            return response.data;
        } catch(e) {
            rejectWithValue({
                message: e.data.message || e.message
            })
        }
    }
);

const initialState = {
    loading: false,
    dataCommonFaq: [],
    selectedData:{}
}


const commonFaqSlice = createSlice({
    name:"cbt",
    initialState: initialState,
    reducers:{
/*        addCommonFaq:(state, action) => {
            const existingIndex = state.dataCommonFaq.findIndex(item => item.questionId === action.payload.questionId);
            if (existingIndex !== -1) {
                state.dataCommonFaq[existingIndex] = action.payload;
            } else {
                state.dataCommonFaq.push(action.payload);
            }
        },*/
        clearCommonFaq:(state) => {
            state.dataCommonFaq= []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCommonFaq.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCommonFaq.fulfilled, (state, action) => {
                state.dataCommonFaq = action.payload;
                state.loading = false;
            })
            .addCase(getCommonFaq.rejected, (state, action) => {
                state.loading = false;
                console.error("Error during get data:", action.error);
            })
            .addCase(getCommonFaqPublic.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCommonFaqPublic.fulfilled, (state, action) => {
                state.dataCommonFaq = action.payload;
                state.loading = false;
            })
            .addCase(getCommonFaqPublic.rejected, (state, action) => {
                state.loading = false;
                console.error("Error during get data:", action.error);
            })
            .addCase(addCommonFaq.pending, (state) => {
                state.loading = true;
            })
            .addCase(addCommonFaq.fulfilled, (state, action) => {
                state.selectedData = action.payload;
                state.loading = false;
            })
            .addCase(addCommonFaq.rejected, (state, action) => {
                state.loading = false;
                console.error("Error during get data:", action.error);
            })
            .addCase(updateCommonFaq.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateCommonFaq.fulfilled, (state, action) => {
                state.selectedData = action.payload;
                state.loading = false;
            })
            .addCase(updateCommonFaq.rejected, (state, action) => {
                state.loading = false;
                console.error("Error during get data:", action.error);
            })
            .addCase(deleteCommonFaq.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteCommonFaq.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteCommonFaq.rejected, (state, action) => {
                state.loading = false;
                console.error("Error during get data:", action.error);
            })
    }
})
export const commonFaqSelector = (state) => state.commonFaq;
export default commonFaqSlice.reducer;
export const {
//    addCommonFaq,
    clearCommonFaq
} = commonFaqSlice.actions;