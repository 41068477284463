import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import jwtAuthAxios from "../../../services/jwtAuth";


const settingGambarEntity = createEntityAdapter({
    selectId: (settingGambar) => settingGambar.id
});
export const getSettingGambar = createAsyncThunk("settingGambar/getAll", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get('v1/setting/gambar/view');
    return response.data;
});

const settingGambarSlice = createSlice({
    name:"settingGambar",
    initialState: settingGambarEntity.getInitialState,
    extraReducers: {
        [getSettingGambar.pending] : (state, action) => {
            state.loading = true;
        },
        [getSettingGambar.fulfilled] : (state, action) => {
            settingGambarEntity.setAll(state,action.payload);
            state.loading = false;
        },
        [getSettingGambar.rejected] : (state, action) => {
            settingGambarEntity.setAll(state,action.payload);
            state.loading = false;
        },
    }
})

export const settingGambarSelectors = settingGambarEntity.getSelectors(state => state.settingGambar);
export default settingGambarSlice.reducer;