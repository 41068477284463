import jwtAuthAxios from "../../services/jwtAuth";
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

export const getMatkul = createAsyncThunk("matkul/getAll", 
async({ page, pageSize, search }, thunkAPI) => {
    try{
        let token = await localStorage.getItem('auth_token');
        jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        const searchParams = search === undefined ? "" : search;
        const url = `v1/mata-kuliah/pageable?page=${page}&size=${pageSize}&search=${searchParams}`
        const response = await jwtAuthAxios.get(url)
        return response.data
    } catch(response){
        throw response
    }
});
export const getMatkulById = createAsyncThunk("matkul/getById", async(id) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/mata-kuliah/${id}`);
    return response.data;
});

export const getMatkulByNama = createAsyncThunk("matkul/getByNama", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/mata-kuliah/nama/${val}`);
    return response.data;
});

export const getMatkulByKode = createAsyncThunk("matkul/getByKode", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/mata-kuliah/kode/${val}`);
    return response.data;
});

export const getMatkulByCategory = createAsyncThunk("matkul/getByCategory", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/mata-kuliah/jenis-mk`);
    return response.data;
});

export const deleteMatkul = createAsyncThunk("matkul/delete", async(id) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    await jwtAuthAxios.delete(`v1/mata-kuliah/${id}`);
    return id;
});

export const saveMatkul = createAsyncThunk("matkul/save", async(payload) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.post('v1/mata-kuliah',payload);
    return response.data;
});
export const updateMatkul = createAsyncThunk("matkul/update", async(request) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    // console.log("request : ",request)
    const response = await jwtAuthAxios.put('v1/mata-kuliah',request);
    return response.data;
});

const initialState = {
    loading: false,
    dataAllMatkul: [],
    dataCategoryMatkul: [],
    dataMatkulByNama:[],
    dataMatkulByKode:[]
    
}

const matkulSlice = createSlice({
    name:"matkul",
    initialState: initialState,
    extraReducers: (builder) => {
    builder
        .addCase(getMatkul.pending, (state) => {
            state.loading = true;
        })
        .addCase(getMatkul.fulfilled, (state, action) => {
            state.dataAllMatkul = action.payload;
            state.loading = false;
        })
        .addCase(getMatkul.rejected, (state, action) => {
            state.loading = false;
            console.error("Error during getMatkul:", action.error);
        })
    builder
        .addCase(getMatkulByCategory.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(getMatkulByCategory.fulfilled, (state, action) => {
            state.dataCategoryMatkul = action.payload;
            state.loading = false;
        })
    builder
        .addCase(getMatkulByNama.pending, (state) => {
            state.loading = true;
        })
        .addCase(getMatkulByNama.fulfilled, (state, action) => {
            state.dataMatkulByNama = action.payload;
            state.loading = false;
        })
        .addCase(getMatkulByNama.rejected, (state, action) => {
            state.loading = false;
            console.error("Error during getMatkul:", action.error);
        })
    builder
        .addCase(getMatkulByKode.pending, (state) => {
            state.loading = true;
        })
        .addCase(getMatkulByKode.fulfilled, (state, action) => {
            state.dataMatkulByKode = action.payload;
            state.loading = false;
        })
        .addCase(getMatkulByKode.rejected, (state, action) => {
            state.loading = false;
            console.error("Error during getMatkul:", action.error);
        })
    builder
        .addCase(getMatkulById.fulfilled, (state, action) => {
            // matkulEntity.setOne(state, action.payload);
        })
        .addCase(saveMatkul.fulfilled, (state, action) => {
            // matkulEntity.addOne(state, action.payload);
        })
        .addCase(deleteMatkul.fulfilled, (state, action) => {
            // matkulEntity.removeOne(state, action.payload);
        })
        .addCase(updateMatkul.fulfilled, (state, action) => {
            // matkulEntity.upsertOne(state, { id: action.payload.id, updates: action.payload });
        });
    }
})
export const matkulSelectors = (state) => state.matkul;
export default matkulSlice.reducer;