import jwtAuthAxios from "../../services/jwtAuth";
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

export const getFakultas = createAsyncThunk("fakultas/getAll", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get('v1/fakultas');
    return response.data;
});

export const getFakultasDetail = createAsyncThunk("fakultas/getDetail", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get('v1/program-studi');
    return response.data;
});
export const getFakultasById = createAsyncThunk("fakultas/getById", async(id) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/fakultas/${id}`);
    return response.data;
});

export const deleteFakultas = createAsyncThunk("fakultas/delete", async(id) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    await jwtAuthAxios.delete(`v1/fakultas/${id}`);
    return id;
});

export const saveFakultas = createAsyncThunk("fakultas/save", async({nama,kode,active}) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.post('v1/fakultas',{
        "kode": kode,
        "nama": nama,
        "active": active,
    });
    return response.data;
});
export const updateFakultas = createAsyncThunk("fakultas/update", async(request) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.put('v1/fakultas',request);
    return response.data;
});

const fakultasEntity = createEntityAdapter({
    selectId: (fakultas) => fakultas.id
});

const fakultasSlice = createSlice({
    name:"fakultas",
    initialState: fakultasEntity.getInitialState,
    extraReducers: {
        [getFakultas.pending] : (state, action) => {
            state.loading = true;
        },
        [getFakultas.fulfilled] : (state, action) => {
            fakultasEntity.setAll(state,action.payload);
            state.loading = false;
        },
        [getFakultasDetail.pending] : (state, action) => {
            state.loading = true;
        },
        [getFakultasDetail.fulfilled] : (state, action) => {
            fakultasEntity.setAll(state,action.payload);
            state.loading = false;
        },
        [getFakultasById.fulfilled] : (state, action) => {
            fakultasEntity.setOne(state,action.payload);
        },
        [saveFakultas.fulfilled] : (state, action) => {
            fakultasEntity.addOne(state,action.payload);
        },
        [deleteFakultas.fulfilled] : (state, action) => {
            fakultasEntity.removeOne(state,action.payload);
        },
        [updateFakultas.fulfilled] : (state, action) => {
            fakultasEntity.upsertOne(state,{id:action.payload.id, updates:action.payload});
        }
    }
})

export const fakultasSelectors = fakultasEntity.getSelectors(state => state.fakultas);
export default fakultasSlice.reducer;