import jwtAuthAxios from "../../../services/jwtAuth";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getPaymentRegistration =
    createAsyncThunk("payment/registration", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get('v1/payment/check/registrasi');
    return response.data;
});

export const checkPayRegUlang =
    createAsyncThunk("payment/registration", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/check/pay/reg/ulang/${val}`);
    return response.data;
});

const initialState = {
    loadingPayment : false,
    loadingPaymentRegUlang : false,
    payloadPaymentRegistration : {},
    dtPayRegUlang : {},
}

const validationSlice = createSlice({
    name:"validationSlice",
    initialState: initialState,
    extraReducers: {
        [getPaymentRegistration.pending] : (state, action) => {
            state.loadingPayment = true;
        },
        [getPaymentRegistration.fulfilled] : (state, action) => {
            state.payloadPaymentRegistration = action.payload;
            state.loadingPayment = false;
        },
        [getPaymentRegistration.pending] : (state, action) => {
            state.loadingPayment = true;
        },
        [checkPayRegUlang.pending] : (state, action) => {
            state.loadingPaymentRegUlang = true;
        },
        [checkPayRegUlang.fulfilled] : (state, action) => {
            state.dtPayRegUlang = action.payload;
            state.loadingPaymentRegUlang = false;
        },
        [checkPayRegUlang.pending] : (state, action) => {
            state.loadingPaymentRegUlang = true;
        },
    }
})

export const validationSelector = (state) => state.validation;
export default validationSlice.reducer;