import jwtAuthAxios from "../../services/jwtAuth";
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

export const getKelas = createAsyncThunk("kelas/getAll", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get('v1/ruang-kelas');
    return response.data;
});

export const getKelasById = createAsyncThunk("kelas/getById", async(id) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/ruang-kelas/${id}`);
    console.log("response : ",JSON.stringify(response.data))
    return response.data;
});

export const deleteKelas = createAsyncThunk("kelas/delete", async(id) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    await jwtAuthAxios.delete(`v1/ruang-kelas/${id}`);
    return id;
});

export const saveKelas = createAsyncThunk("kelas/save", async({nama,kapasitas,keterangan,gedungId}) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.post('v1/ruang-kelas',{
        "nama": nama,
        "kapasitas": kapasitas,
        "keterangan": keterangan,
        "gedungId" : gedungId
    });
    return response.data;
});
export const updateKelas = createAsyncThunk("kelas/update", async(request) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    console.log("request : ",request)
    const response = await jwtAuthAxios.put('v1/ruang-kelas',request);
    return response.data;
});

const kelasEntity = createEntityAdapter({
    selectId: (kelas) => kelas.id
});

const initialState = {
    loading: false,
    dataKelas: []
}

const kelasSlice = createSlice({
    name:"kelas",
    initialState: initialState,
    extraReducers: {
        [getKelas.pending] : (state, action) => {
            state.loading = true;
        },
        [getKelas.fulfilled] : (state, action) => {
            state.dataKelas = action.payload
            kelasEntity.setAll(state,action.payload);
            state.loading = false;
        },
        [getKelasById.fulfilled] : (state, action) => {
            kelasEntity.setOne(state,action.payload);
        },
        [saveKelas.fulfilled] : (state, action) => {
            kelasEntity.addOne(state,action.payload);
        },
        [deleteKelas.fulfilled] : (state, action) => {
            kelasEntity.removeOne(state,action.payload);
        },
        [updateKelas.fulfilled] : (state, action) => {
            kelasEntity.upsertOne(state,{id:action.payload.id, updates:action.payload});
        }
    }
})

export const kelasSelectors = (state => state.kelas);
export default kelasSlice.reducer;