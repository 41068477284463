import jwtAuthAxios from "../../../services/jwtAuth";
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

export const checkToken = createAsyncThunk("auth/checkToken", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    /*const response = await jwtAuthAxios.get(`v1/users/me`);
    return response.data;*/
    return await jwtAuthAxios.get(`v1/users/me`);
});

const initialState = {
    loading : false,
    response : {}
}

const authenticationServicesSlice = createSlice({
    name:"authenticationServicesSlice",
    initialState: initialState,
    extraReducers: {
        [checkToken.pending] : (state, action) => {
            state.loading = true;
        },
        [checkToken.fulfilled] : (state, action) => {
            state.response = action.response.payload;
            state.loading = false;
        },
        [checkToken.pending] : (state, action) => {
            state.loading = true;
        },
    }
})

export const authenticationServicesSelector = (state) => state.authenticationServices;
export default authenticationServicesSlice.reducer;