import jwtAuthAxios from "../../../services/jwtAuth";
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

export const getBillingInfo = createAsyncThunk("billing/get", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/billing/information/trxid/${val}`);
    return response.data;
});

const initialState = {
    loadingBillingInfo : false,
    responseBillingInfo : {}
}

const billingInfoSlice = createSlice({
    name:"paySlice",
    initialState: initialState,
    extraReducers: {
        [getBillingInfo.pending] : (state, action) => {
            state.loadingBillingInfo = true;
        },
        [getBillingInfo.fulfilled] : (state, action) => {
            state.responseBillingInfo = action.payload;
            state.loadingBillingInfo = false;
        },
        [getBillingInfo.pending] : (state, action) => {
            state.loadingBillingInfo = true;
        },
    }
})

export const billingInfoSelector = (state) => state.billingInfo;
export default billingInfoSlice.reducer;