import { generateTemplate } from "configs/utils/Excel";
import jwtAuthAxios from "../../services/jwtAuth";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


export const getDetailDataDaftar = createAsyncThunk("detailDataDaftar/getAll", async() => {

    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get('/v1/pendaftar/detail-all');
    return response.data;

});

export const downloadTemplateExcel = createAsyncThunk("laporan/export/downloadTemplate",
	async (payload, { rejectWithValue }) => {
		try {
			const workbook = await generateTemplate(payload);

			// Save to blob
			const buffer = await workbook.xlsx.writeBuffer();
			const blob = new Blob([buffer], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
			});
			const url = URL.createObjectURL(blob);

			// Create a temporary link element
			const link = document.createElement('a');
			link.href = url;
			link.download = 'Laporan_Pendaftaran.xlsx';

			// Trigger a click on the link to initiate the download
			link.click();

			// Cleanup
			URL.revokeObjectURL(url);
		} catch(e) {
			rejectWithValue({
				message: e.data.message || e.message
			})
		}
	}
);


const initialState = {
    loading: false,
    dataList: [],
    selectedData:{},
    dataDownload:[]
}


const detailDataDaftarSlice = createSlice({
    name:"detailDataDaftar",
    initialState: initialState,
    reducers:{
/*        addCommonFaq:(state, action) => {
            const existingIndex = state.dataCommonFaq.findIndex(item => item.questionId === action.payload.questionId);
            if (existingIndex !== -1) {
                state.dataCommonFaq[existingIndex] = action.payload;
            } else {
                state.dataCommonFaq.push(action.payload);
            }
        },*/
        clearCommonFaq:(state) => {
            state.dataList= []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDetailDataDaftar.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDetailDataDaftar.fulfilled, (state, action) => {
                state.dataList = action.payload;
                state.loading = false;
            })
            .addCase(getDetailDataDaftar.rejected, (state, action) => {
                state.loading = false;
                console.error("Error during get data:", action.error);
            })
        builder
            .addCase(downloadTemplateExcel.pending, (state) => {
                state.loading = true;
            })
            .addCase(downloadTemplateExcel.fulfilled, (state, action) => {
                state.dataDownload = action.payload;
                state.loading = false;
            })
            .addCase(downloadTemplateExcel.rejected, (state, action) => {
                state.loading = false;
                console.error("Error during get data:", action.error);
            })
    }
})
export const detailDataDaftarSelector = (state) => state.detailDataDaftar;
export default detailDataDaftarSlice.reducer;
export const {
//    addCommonFaq,
    clearCommonFaq
} = detailDataDaftarSlice.actions;