import jwtAuthAxios from "../../services/jwtAuth";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getConfigPembayaran = createAsyncThunk("configPembayaran/getAll", async() => {

    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get('/v1/config-pembayaran');
    return response.data;

});

export const addConfigPembayaran = createAsyncThunk("configPembayaran/save",
    async (payload, { rejectWithValue }) => {
        try {
            const token = await localStorage.getItem('auth_token');
            jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            const response = await jwtAuthAxios.post('v1/config-pembayaran', payload);
            return response.data;
        } catch(e) {
            rejectWithValue({
                message: e.data.message || e.message
            })
        }
    }
);
export const updateConfigPembayaran = createAsyncThunk("configPembayaran/update",
    async (payload, { rejectWithValue }) => {
        try {
            const token = await localStorage.getItem('auth_token');
            jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            const response = await jwtAuthAxios.put('v1/config-pembayaran', payload);
            return response.data;
        } catch(e) {
            rejectWithValue({
                message: e.data.message || e.message
            })
        }
    }
);
export const deleteConfigPembayaran = createAsyncThunk("configPembayaran/delete",
    async (id, { rejectWithValue }) => {
        try {
            const token = await localStorage.getItem('auth_token');
            jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            const response = await jwtAuthAxios.delete(`v1/config-pembayaran/${id}`);
            return response.data;
        } catch(e) {
            rejectWithValue({
                message: e.data.message || e.message
            })
        }
    }
);

const initialState = {
    loading: false,
    dataList: [],
    selectedData:{}
}


const configPembayaranSlice = createSlice({
    name:"configPembayaran",
    initialState: initialState,
    reducers:{
        clearConfigPembayaran:(state) => {
            state.dataList= []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getConfigPembayaran.pending, (state) => {
                state.loading = true;
            })
            .addCase(getConfigPembayaran.fulfilled, (state, action) => {
                state.dataList   = action.payload;
                state.loading = false;
            })
            .addCase(getConfigPembayaran.rejected, (state, action) => {
                state.loading = false;
                console.error("Error during get data:", action.error);
            })
            .addCase(addConfigPembayaran.pending, (state) => {
                state.loading = true;
            })
            .addCase(addConfigPembayaran.fulfilled, (state, action) => {
                state.selectedData = action.payload;
                state.loading = false;
            })
            .addCase(addConfigPembayaran.rejected, (state, action) => {
                state.loading = false;
                console.error("Error during get data:", action.error);
            })
            .addCase(updateConfigPembayaran.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateConfigPembayaran.fulfilled, (state, action) => {
                state.selectedData = action.payload;
                state.loading = false;
            })
            .addCase(updateConfigPembayaran.rejected, (state, action) => {
                state.loading = false;
                console.error("Error during get data:", action.error);
            })
            .addCase(deleteConfigPembayaran.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteConfigPembayaran.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteConfigPembayaran.rejected, (state, action) => {
                state.loading = false;
                console.error("Error during get data:", action.error);
            })
    }
})
export const configPembayaranSelector = (state) => state.configPembayaran;
export default configPembayaranSlice.reducer;
export const {
    clearConfigPembayaran
} = configPembayaranSlice.actions;