import jwtAuthAxios from "../../services/jwtAuth";
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

export const getGedung = createAsyncThunk("gedung/getAll", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get('v1/gedung');
    return response.data;
});
export const getGedungById = createAsyncThunk("gedung/getById", async(id) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/gedung/${id}`);
    return response.data;
});

export const deleteGedung = createAsyncThunk("gedung/delete", async(id) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    await jwtAuthAxios.delete(`v1/gedung/${id}`);
    return id;
});

export const saveGedung = createAsyncThunk("gedung/save", async({nama,kode,active}) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.post('v1/gedung',{
        "kode": kode,
        "nama": nama,
        "active": active,
    });
    return response.data;
});
export const updateGedung = createAsyncThunk("gedung/update", async(request) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    console.log("request : ",request)
    const response = await jwtAuthAxios.put('v1/gedung',request);
    return response.data;
});

const gedungEntity = createEntityAdapter({
    selectId: (gedung) => gedung.id
});

const gedungSlice = createSlice({
    name:"gedung",
    initialState: gedungEntity.getInitialState,
    extraReducers: {
        [getGedung.pending] : (state, action) => {
            state.loading = true;
        },
        [getGedung.fulfilled] : (state, action) => {
            gedungEntity.setAll(state,action.payload);
            state.loading = false;
        },
        [getGedungById.fulfilled] : (state, action) => {
            gedungEntity.setOne(state,action.payload);
        },
        [saveGedung.fulfilled] : (state, action) => {
            gedungEntity.addOne(state,action.payload);
        },
        [deleteGedung.fulfilled] : (state, action) => {
            gedungEntity.removeOne(state,action.payload);
        },
        [updateGedung.fulfilled] : (state, action) => {
            gedungEntity.upsertOne(state,{id:action.payload.id, updates:action.payload});
        }
    }
})

export const gedungSelectors = gedungEntity.getSelectors(state => state.gedung);
export default gedungSlice.reducer;