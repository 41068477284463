import jwtAuthAxios from "../../services/jwtAuth";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getKalenderAkademikAll = createAsyncThunk("kalenderAkademik/getAll", 
    async({page = 1, size = 3, sort='periodeId~asc'}) => {
        try{
            let token = await localStorage.getItem('auth_token');
            jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            const response = await jwtAuthAxios.get(`v1/kalender-akademik/pageable?page=${page}&size=${size}&sort=${sort}`)
            return response.data.data.result
        } catch(response){
            throw response
        }
    }
);

export const getByKalenderAkademikByPeriodeId = createAsyncThunk("kalenderAkademik/getByKalenderAkademikByPeriodeId", 
async(periodeId) => {
    try{
        let token = await localStorage.getItem('auth_token');
        jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        const url = `v1/kalender-akademik/${periodeId}`
        const response = await jwtAuthAxios.get(url)
        return response.data
    } catch(response){
        throw response
    }
});



const initialState = {
    loading: false,
    dataAllKalenderAkademik: [],
    dataKurikulumAkademikPeriode: []
}

const kalenderAkademikSlice = createSlice({
    name:"kalenderAkademik",
    initialState: initialState,
    extraReducers: (builder) => {
    builder
        .addCase(getKalenderAkademikAll.pending, (state) => {
            state.loading = true;
        })
        .addCase(getKalenderAkademikAll.fulfilled, (state, action) => {
            state.dataAllKalenderAkademik = action.payload;
            state.loading = false;
        })
        .addCase(getKalenderAkademikAll.rejected, (state, action) => {
            state.loading = false;
            console.error("Error during getMatkul:", action.error);
        })
    builder
        .addCase(getByKalenderAkademikByPeriodeId.pending, (state) => {
            state.loading = true;
        })
        .addCase(getByKalenderAkademikByPeriodeId.fulfilled, (state, action) => {
            state.dataKurikulumAkademikPeriode = action.payload;
            state.loading = false;
        })
        .addCase(getByKalenderAkademikByPeriodeId.rejected, (state, action) => {
            state.loading = false;
            console.error("Error during getMatkul:", action.error);
        })
        
    }
})
export const kalenderAkademikSelector = (state) => state.kalenderAkademik;
export default kalenderAkademikSlice.reducer;