import jwtAuthAxios from "../../services/jwtAuth";
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

export const getPendidikan = createAsyncThunk("pendidikan/getAll", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get('v1/jenjang-pendidikan');
    return response.data;
});
export const getPendidikanById = createAsyncThunk("pendidikan/getById", async(id) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/jenjang-pendidikan/${id}`);
    console.log("response : ",JSON.stringify(response.data))
    return response.data;
});

export const deletePendidikan = createAsyncThunk("pendidikan/delete", async(id) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    await jwtAuthAxios.delete(`v1/jenjang-pendidikan/${id}`);
    return id;
});

export const savePendidikan = createAsyncThunk("pendidikan/save", async({nama,kode,active}) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.post('v1/jenjang-pendidikan',{
        "kode": kode,
        "nama": nama,
        "active": active,
    });
    return response.data;
});
export const updatePendidikan = createAsyncThunk("pendidikan/update", async(request) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    console.log("request : ",request)
    const response = await jwtAuthAxios.put('v1/jenjang-pendidikan',request);
    return response.data;
});

const pendidikanEntity = createEntityAdapter({
    selectId: (pedidikan) => pedidikan.id
});

const pendidikanSlice = createSlice({
    name:"pendidikan",
    initialState: pendidikanEntity.getInitialState,
    extraReducers: {
        [getPendidikan.pending] : (state, action) => {
            state.loading = true;
        },
        [getPendidikan.fulfilled] : (state, action) => {
            pendidikanEntity.setAll(state,action.payload);
            state.loading = false;
        },
        [getPendidikanById.fulfilled] : (state, action) => {
            pendidikanEntity.setOne(state,action.payload);
        },
        [savePendidikan.fulfilled] : (state, action) => {
            pendidikanEntity.addOne(state,action.payload);
        },
        [deletePendidikan.fulfilled] : (state, action) => {
            pendidikanEntity.removeOne(state,action.payload);
        },
        [updatePendidikan.fulfilled] : (state, action) => {
            pendidikanEntity.upsertOne(state,{id:action.payload.id, updates:action.payload});
        }
    }
})

export const pendidikanSelectors = pendidikanEntity.getSelectors(state => state.pendidikan);
export default pendidikanSlice.reducer;