import jwtAuthAxios from "../../services/jwtAuth";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


export const getJalurPendaftaran = createAsyncThunk("jalurPendaftaran/getAll", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get('/v1/jalur-pendaftaran');
    return response.data;
});
export const getListProdi = createAsyncThunk("detailProdi/getAll", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get('/v1/program-studi');
    return response.data;
});

const initialState = {
    loading: false,
    dataList: [],
    dataProdi :[],
    selectedData:{}
}

const jalurPendaftaranSlice = createSlice({
    name:"jalurPendaftaran",
    initialState: initialState,
    reducers:{
        clearJalurPendaftaran:(state) => {
            state.dataList= []
        },
        clearDetailProdi:(state) => {
            state.dataProdi= []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getJalurPendaftaran.pending, (state) => {
                state.loading = true;
            })
            .addCase(getJalurPendaftaran.fulfilled, (state, action) => {
                state.dataList = action.payload;
                state.loading = false;
            })
            .addCase(getJalurPendaftaran.rejected, (state, action) => {
                state.loading = false;
                console.error("Error during get data:", action.error);
            })
            .addCase(getListProdi.pending, (state) => {
                state.loading = true;
            })
            .addCase(getListProdi.fulfilled, (state, action) => {
                state.dataProdi = action.payload;
                state.loading = false;
            })
            .addCase(getListProdi.rejected, (state, action) => {
                state.loading = false;
                console.error("Error during get data:", action.error);
            })

    }
})
export const jalurPendaftaranSelector = (state) => state.jalurPendaftaran;
export const {
    clearJalurPendaftaran
} = jalurPendaftaranSlice.actions;

export default jalurPendaftaranSlice.reducer;