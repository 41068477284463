import React, {lazy, Suspense, memo, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {ConfigProvider, Spin} from 'antd';
import Loading from 'components/shared-components/Loading';
import { lightTheme, darkTheme } from 'configs/ThemeConfig';
import { resources } from 'lang';
import useBodyClass from 'utils/hooks/useBodyClass';
import Routes from 'routes'
import {authenticationServicesSelector,checkToken} from "../store/slices/authentication/authenticationServices";
import { signOut } from 'store/slices/authSlice';

const AppLayout = lazy(() => import('./AppLayout'));
const AuthLayout = lazy(() => import('./AuthLayout'));

const Layouts = () => {
	const dispatch = useDispatch();
	const {response} = useSelector(authenticationServicesSelector);
	const [loading,setLoading] = useState(false);

	const token = useSelector(state => state.auth.token);

	const blankLayout = useSelector(state => state.theme.blankLayout);

	const Layout = token && !blankLayout ? AppLayout : AuthLayout;

	const locale = useSelector(state => state.theme.locale);

	const direction = useSelector(state => state.theme.direction);

	const currentTheme = useSelector(state => state.theme.currentTheme);

	const currentAppLocale = resources[locale];

	useBodyClass(`dir-${direction}`);

	const themeConfig = currentTheme === 'light' ? {...lightTheme} : {...darkTheme}

	useEffect(() => {
		setLoading(true);
		dispatch(checkToken()).then((response) => {
			//console.log({response})
			if ("Request failed with status code 403" === response.error.message) {
				// redirect to logout
				dispatch(signOut());
			} else {
				// do nothing
				console.log("authenticated")
			}
			setLoading(false);
		}).catch(reason => {
			//console.log({reason})
			setLoading(false);
		})
	}, [dispatch]);

	return (
		<Spin spinning={loading}>
			<ConfigProvider theme={themeConfig} direction={direction} locale={currentAppLocale.antd}>
				<Suspense fallback={<Loading cover="content"/>}>
					<Layout>
						<Routes />
					</Layout>
				</Suspense>
			</ConfigProvider>
		</Spin>
	)
}

export default memo(Layouts)