import jwtAxios from "axios";
import {AUTH_TOKEN} from "../constants/AuthConstant";
import {notification} from "antd";
import store from "../store";
import {signOutSuccess} from "../store/slices/authSlice";

const jwtAuthAxios = jwtAxios.create({
    baseURL: "/pmb/api/",
    headers: {
        'Content-Type': 'application/json'
    }
});


export const setTokenManual = (token) => {
    //console.log('set token : ',token)
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
};
const TOKEN_PAYLOAD_KEY = 'Authorization'
/*
jwtAuthAxios.interceptors.request.use(config => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;
    if (jwtToken) {
        config.headers[TOKEN_PAYLOAD_KEY] = jwtToken
    }
    return config
}, error => {
    // Do something with request error here
    notification.error({
        message: 'Error'
    })
    Promise.reject(error)
})
*/

// API respone interceptor
const unauthorizedCode = [400, 401, 403]
/*jwtAuthAxios.interceptors.response.use( (response) => {
    return response.data
}, (error) => {
    let notificationParam = {
        message: ''
    }
    console.log("Unauthorize : ",error.response.status);
    // Remove token and redirect
    if (unauthorizedCode.includes(error.response.status)) {
        notificationParam.message = 'Session Anda Telah Berakhir'
        notificationParam.description = 'Mohon login kembali'
        localStorage.removeItem(AUTH_TOKEN)
        store.dispatch(signOutSuccess())
        console.log("Unauthorize : ",error.response.status);
    }

    if (error.response.status === 404) {
        notificationParam.message = 'Not Found'
    }

    if (error.response.status === 500) {
        notificationParam.message = 'Internal Server Error'
    }

    if (error.response.status === 508) {
        notificationParam.message = 'Time Out'
    }

    notification.error(notificationParam)

    return Promise.reject(error);
});*/


/*export const setToken = (token,roles) => {
    //console.log("roles : "+roles);
    //console.log(token)
    //console.log(roles)
    if(token) {
        jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        sessionStorage.getItem('token');
        sessionStorage.setItem('roles', roles);
        sessionStorage.setItem('isAuthenticated',true);
    } else {
        delete jwtAuthAxios.defaults.headers.common['Authorization'];
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('roles');
        sessionStorage.removeItem('isAuthenticated');
    }
};

export const setAuthToken = (token,roles) => {
    //console.log("roles : "+roles);
    //console.log(token)
    //console.log(roles)
    if(token) {
        jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('roles', roles);
        sessionStorage.setItem('isAuthenticated',true);
    } else {
        delete jwtAuthAxios.defaults.headers.common['Authorization'];
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('roles');
        sessionStorage.removeItem('isAuthenticated');
    }
};

export const getAuthToken = () => {
    return sessionStorage.getItem("token");
};*/

/*jwtAuthAxios.interceptors.response.use(
    res => res,
    err => {
        if(err.response && err.response.data.type === "token-invalid") {
            //todo logout the user
        }
        return Promise.reject(err);
    }
);*/
/*const registerAxios = jwtAxios.create({
    baseURL: "/pmb/",
    headers: {
        'Content-Type': 'application/json'
    }
});*/

//todo: define interceptors and other configuration like baseURL, headers etc. here
export default jwtAuthAxios;