import jwtAuthAxios from "../../services/jwtAuth";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


export const getSoal = createAsyncThunk("cbt/getSoal", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get('/v1/cbt/soal');
    return response.data;
});

export const getCbtInfo = createAsyncThunk("cbt/getCbtInfo", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get('/v1/pmb/cbt');
    return response.data;
});

export const saveHasilCbt = createAsyncThunk("cbt/saveHasilCbt", async(payload) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.post('v1/cbt/soal',payload);
    return response.data;
});

export const createSoalManual = createAsyncThunk("cbt/createSoalManuak", async(payload) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.post('/v1/cbt-akses/create',payload);
    return response.data;
});

export const getMasterCbt = createAsyncThunk("masterCbt/getAll", async(id) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/cbt-akses/${id}`);
    return response.data;
});

export const getHasilUjian = createAsyncThunk("masterCbt/getHasilUjian", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/view/cbt/hasil/user/all`);
    return response.data;
});


const initialState = {
    loading: false,
    listSoal: [],
    hasilCbt: [],
    cbtInfo:[],
    updatedData: [],
    dataCreateSoal: [],
    dataAllMaster:[],
    loadingHasilUjian:false,
    dataHasilUjian:[]
}


const cbtSlice = createSlice({
    name:"cbt",
    initialState: initialState,
    reducers:{
        addAnswerTest:(state, action) => {
            const existingIndex = state.updatedData.findIndex(item => item.questionId === action.payload.questionId);
            if (existingIndex !== -1) {
                state.updatedData[existingIndex] = action.payload;
            } else {
                state.updatedData.push(action.payload);
            }
        },
        clearDataAnswer:(state) => {
            state.updatedData= []
        }
    },
    extraReducers: (builder) => {
    builder
        .addCase(getSoal.pending, (state) => {
            state.loading = true;
        })
        .addCase(getSoal.fulfilled, (state, action) => {
            state.listSoal = action.payload;
            state.loading = false;
        })
        .addCase(getSoal.rejected, (state, action) => {
            state.loading = false;
            console.error("Error during getMatkul:", action.error);
        })
    builder
        .addCase(getCbtInfo.pending, (state) => {
            state.loading = true;
        })
        .addCase(getCbtInfo.fulfilled, (state, action) => {
            state.cbtInfo = action.payload;
            state.loading = false;
        })
        .addCase(getCbtInfo.rejected, (state, action) => {
            state.loading = false;
            console.error("Error during getMatkul:", action.error);
        })
    builder
        .addCase(saveHasilCbt.pending, (state) => {
            state.loading = true;
        })
        .addCase(saveHasilCbt.fulfilled, (state, action) => {
            state.hasilCbt = action.payload;
            state.loading = false;
        })
        .addCase(saveHasilCbt.rejected, (state, action) => {
            state.loading = false;
            console.error("Error during getMatkul:", action.error);
        })
    builder
        .addCase(createSoalManual.pending, (state) => {
            state.loading = true;
        })
        .addCase(createSoalManual.fulfilled, (state, action) => {
            state.dataCreateSoal = action.payload;
            state.loading = false;
        })
        .addCase(createSoalManual.rejected, (state, action) => {
            state.loading = false;
            console.error("Error during getMatkul:", action.error);
        })
    builder
        .addCase(getMasterCbt.pending, (state) => {
            state.loading = true;
        })
        .addCase(getMasterCbt.fulfilled, (state, action) => {
            state.dataAllMaster = action.payload;
            state.loading = false;
        })
        .addCase(getMasterCbt.rejected, (state, action) => {
            state.loading = false;
            console.error("Error during getMatkul:", action.error);
        })
    builder
        .addCase(getHasilUjian.pending, (state) => {
            state.loadingHasilUjian = true;
        })
        .addCase(getHasilUjian.fulfilled, (state, action) => {
            state.dataHasilUjian = action.payload;
            state.loadingHasilUjian = false;
        })
        .addCase(getHasilUjian.rejected, (state, action) => {
            state.loadingHasilUjian = false;
            console.error("Error during getMatkul:", action.error);
        })
    }
})
export const cbtSelector = (state) => state.cbt;
export default cbtSlice.reducer;
export const { addAnswerTest, clearDataAnswer } = cbtSlice.actions;