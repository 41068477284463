import jwtAuthAxios from "../../../services/jwtAuth";
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

export const getDashPilihanAll = createAsyncThunk("dashboard/pilihanAll", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/dashboard/pilihan/all`);
    return response.data;
});
export const getDashPilihanAllTipe1 = createAsyncThunk("dashboard/getDashPilihanAllTipe1", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/dashboard/pilihan/all/tipe1`);
    return response.data;
});
export const getDashPilihanAllTipe2 = createAsyncThunk("dashboard/getDashPilihanAllTipe1", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/dashboard/pilihan/all/tipe2`);
    return response.data;
});
export const getDashPilihan01 = createAsyncThunk("dashboard/pilihan01", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/dashboard/pilihan01`);
    return response.data;
});

export const getDashPilihan02 = createAsyncThunk("dashboard/pilihan02", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/dashboard/pilihan02`);
    return response.data;
});
export const getDashPilihan03 = createAsyncThunk("dashboard/pilihan03", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/dashboard/pilihan03`);
    return response.data;
});
export const getDashAsalSekolah = createAsyncThunk("dashboard/asalSekolah", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/dashboard/asal_sekolah`);
    return response.data;
});
export const getDashAsalSekolahTipe1 = createAsyncThunk("dashboard/getDashAsalSekolahTipe1", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/dashboard/asal_sekolah/tipe1`);
    return response.data;
});

const initialState = {
    loadingDashPilihanAll : false,
    loadingDashPilihanAllTipe1 : false,
    loadingDashPilihan01 : false,
    loadingDashTipe2 : false,
    loadingDashPilihan02 : false,
    loadingDashPilihan03 : false,
    loadingDashAsalSekolah : false,
    loadingDashAsalSekolahTipe1 : false,
    dataDashPilihan01 : [],
    dataDashPilihanAll : [],
    dataDashPilihanAllTipe1 : {
        jurusan : ['01','02','03'],
        data01 : [0,0,0],
        data02 : [0,0,0],
        data03 : [0,0,0],
    },
    dataDashPilihan02 : [],
    dataDashPilihan03 : [],
    dataDashTipe2 : [
        {
            name: 'S1 Pertambangan',
            data: [0, 0, 0]
        }, {
            name: 'S1 Teknik Informatika',
            data: [0, 0, 0]
        }, {
            name: 'S1 Teknik Mesin',
            data: [0, 0, 0]
        }
    ],
    dataDashAsalSekolah : [],
    dataDashAsalSekolahTipe1 : {
        data : ['0','1','2'],
        asalSekolah : [0,0,0]
    },
}

const dashPilihanSlice = createSlice({
    name:"dashPilihanSlice",
    initialState: initialState,
    extraReducers: {
        [getDashPilihan01.pending] : (state, action) => {
            state.loadingDashPilihan01 = true;
        },
        [getDashPilihan01.fulfilled] : (state, action) => {
            state.dataDashPilihan01 = action.payload;
            state.loadingDashPilihan01 = false;
        },
        [getDashPilihan01.pending] : (state, action) => {
            state.loadingDashPilihan01 = true;
        },
        [getDashPilihan02.pending] : (state, action) => {
            state.loadingDashPilihan02 = true;
        },
        [getDashPilihan02.fulfilled] : (state, action) => {
            state.dataDashPilihan02 = action.payload;
            state.loadingDashPilihan02 = false;
        },
        [getDashPilihan02.pending] : (state, action) => {
            state.loadingDashPilihan02 = true;
        },
        [getDashPilihan03.pending] : (state, action) => {
            state.loadingDashPilihan03 = true;
        },
        [getDashPilihan03.fulfilled] : (state, action) => {
            state.dataDashPilihan03 = action.payload;
            state.loadingDashPilihan03 = false;
        },
        [getDashPilihan03.pending] : (state, action) => {
            state.loadingDashPilihan03 = true;
        },
        [getDashAsalSekolah.pending] : (state, action) => {
            state.loadingDashAsalSekolah = true;
        },
        [getDashAsalSekolah.fulfilled] : (state, action) => {
            state.dataDashAsalSekolah = action.payload;
            state.loadingDashAsalSekolah = false;
        },
        [getDashAsalSekolah.pending] : (state, action) => {
            state.loadingDashAsalSekolah = true;
        },
        [getDashPilihanAll.pending] : (state, action) => {
            state.loadingDashPilihanAll = true;
        },
        [getDashPilihanAll.fulfilled] : (state, action) => {
            state.dataDashPilihanAll = action.payload;
            state.loadingDashPilihanAll = false;
        },
        [getDashPilihanAll.pending] : (state, action) => {
            state.loadingDashPilihanAll = true;
        },
        [getDashPilihanAllTipe1.pending] : (state, action) => {
            state.loadingDashPilihanAllTipe1 = true;
        },
        [getDashPilihanAllTipe1.fulfilled] : (state, action) => {
            state.dataDashPilihanAllTipe1 = action.payload;
            state.loadingDashPilihanAllTipe1 = false;
        },
        [getDashPilihanAllTipe1.pending] : (state, action) => {
            state.loadingDashPilihanAllTipe1 = true;
        },

        [getDashAsalSekolahTipe1.pending] : (state, action) => {
            state.loadingDashAsalSekolahTipe1 = true;
        },
        [getDashAsalSekolahTipe1.fulfilled] : (state, action) => {
            state.dataDashAsalSekolahTipe1 = action.payload;
            state.loadingDashAsalSekolahTipe1 = false;
        },
        [getDashAsalSekolahTipe1.pending] : (state, action) => {
            state.loadingDashAsalSekolahTipe1 = true;
        },


        [getDashPilihanAllTipe2.pending] : (state, action) => {
            state.loadingDashTipe2 = true;
        },
        [getDashPilihanAllTipe2.fulfilled] : (state, action) => {
            state.dataDashTipe2 = action.payload;
            state.loadingDashTipe2 = false;
        },
        [getDashPilihanAllTipe2.pending] : (state, action) => {
            state.loadingDashTipe2 = true;
        },
    }
})

export const dashPilihanSelector = (state) => state.dashPilihan;
export default dashPilihanSlice.reducer;