import jwtAuthAxios from "../../services/jwtAuth";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getAppData = createAsyncThunk("appData/getAll", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get('v1/app-data');
    return response.data;
});
export const getAppDataDetailByProdi = createAsyncThunk("appData/getAllByProdi", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get('v1/app-data/filter/prodi');
    return response.data;
});
export const addAppData = createAsyncThunk("appData/save",
    async (payload, { rejectWithValue }) => {
        try {
            const token = await localStorage.getItem('auth_token');
            jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            const response = await jwtAuthAxios.post('v1/app-data', payload);
            return response.data;
        } catch(e) {
            rejectWithValue({
                message: e.data.message || e.message
            })
        }
    }
);
export const addAppDataWithoutProdi = createAsyncThunk("appData/saveNp",
    async (payload, { rejectWithValue }) => {
        try {
            const token = await localStorage.getItem('auth_token');
            jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            const response = await jwtAuthAxios.post('v1/app-data/np', payload);
            return response.data;
        } catch(e) {
            rejectWithValue({
                message: e.data.message || e.message
            })
        }
    }
);



export const updateAppData = createAsyncThunk("appData/update",
    async (payload, { rejectWithValue }) => {
        try {
            const token = await localStorage.getItem('auth_token');
            jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            const response = await jwtAuthAxios.patch('v1/app-data', payload);
            return response.data;
        } catch(e) {
            rejectWithValue({
                message: e.data.message || e.message
            })
        }
    }
);
export const deleteAppData = createAsyncThunk("appData/delete",
    async (id, { rejectWithValue }) => {
        try {
            const token = await localStorage.getItem('auth_token');
            jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            const response = await jwtAuthAxios.delete(`v1/app-data/${id}`);
            return response.data;
        } catch(e) {
            rejectWithValue({
                message: e.data.message || e.message
            })
        }
    }
);
export const downloadAppDataFiles = createAsyncThunk("appData/download",
    async (id, { rejectWithValue }) => {
        try {
            const token = await localStorage.getItem('auth_token');
            jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            const response = await jwtAuthAxios.get(`v1/app-data/download/${id}`,{
                responseType: 'arraybuffer',
            });
            return response.data;
        } catch(e) {
            rejectWithValue({
                message: e.data.message || e.message
            })
        }
    }
);

const initialState = {
    loading: false,
    dataList: [],
    dataListProdi: [],
    selectedData:{},
    dataDownload:null
}

const appDataSlice = createSlice({
    name:"appData",
    initialState: initialState,
    reducers:{
        clearAppData:(state) => {
            state.dataProdi= []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(addAppData.pending, (state) => {
                state.loading = true;
            })
            .addCase(addAppData.fulfilled, (state, action) => {
                state.dataList = action.payload;
                state.loading = false;
            })
            .addCase(addAppData.rejected, (state, action) => {
                state.loading = false;
                console.error("Error during get data:", action.error);
            })
            .addCase(getAppDataDetailByProdi.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAppDataDetailByProdi.fulfilled, (state, action) => {
                state.dataListProdi = action.payload;
                state.loading = false;
            })
            .addCase(getAppDataDetailByProdi.rejected, (state, action) => {
                state.loading = false;
                console.error("Error during get data:", action.error);
            })
            .addCase(updateAppData.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateAppData.fulfilled, (state, action) => {
                //state.dataList = action.payload;
                state.loading = false;
            })
            .addCase(updateAppData.rejected, (state, action) => {
                state.loading = false;
                console.error("Error during get data:", action.error);
            })
            .addCase(getAppData.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAppData.fulfilled, (state, action) => {
                state.dataList = action.payload;
                state.loading = false;
            })
            .addCase(getAppData.rejected, (state, action) => {
                state.loading = false;
                console.error("Error during get data:", action.error);
            })
            .addCase(deleteAppData.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteAppData.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteAppData.rejected, (state, action) => {
                state.loading = false;
                console.error("Error during get data:", action.error);
            })
            .addCase(downloadAppDataFiles.pending, (state) => {
                state.loading = true;
            })
            .addCase(downloadAppDataFiles.fulfilled, (state, action) => {
                state.dataDownload = action.payload
                state.loading = false;
            })
            .addCase(downloadAppDataFiles.rejected, (state, action) => {
                state.loading = false;
                console.error("Error during get data:", action.error);
            })

    }
})
export const appDataSelector = (state) => state.appData;
export const {
    clearAppData
} = appDataSlice.actions;

export default appDataSlice.reducer;