import jwtAuthAxios from "../../../services/jwtAuth";
import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
export const downloadPp = createAsyncThunk("download/pp", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/download/image/profile/${val}`);
    return response.data;
});
export const downloadKtp = createAsyncThunk("download/ktp", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/download/image/ktp/${val}`);
    return response.data;
});
export const downloadKk = createAsyncThunk("download/kk", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/download/image/kk/${val}`);
    return response.data;
});
export const downloadTranskip = createAsyncThunk("download/transkip", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/download/image/transkip/${val}`);
    return response.data;
});


export const downloadTranskipNilai = createAsyncThunk("download/transkipNilai", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/download/image/transkip/${val}`);
    return response.data;
});

export const downloadIjasah = createAsyncThunk("download/ijasah", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/download/image/ijasah/${val}`);
    return response.data;
});
export const downloadSuratIzin = createAsyncThunk("download/surat-izin", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/download/image/surat-izin/${val}`);
    return response.data;
});
export const downloadSuratPindah = createAsyncThunk("download/surat-pindah", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/download/image/surat-pindah/${val}`);
    return response.data;
});

const initialState = {
    loadingPp : false,
    dataPP : {},
    loadingKk : false,
    dataKk : {},
    loadingKtp : false,
    dataKtp : {},
    loadingTranskip : false,
    dataTranskip : {},
    loadingIjasah : false,
    dataIjasah : {},
    loadingSuratIzin : false,
    dataSuratIzin : {},
    loadingSuratPindah : false,
    dataSuratPindah : {},
    loadingTranskipNilai : false,
    dataTranskipNilai : {},
}

const downloadImageSlice = createSlice({
    name:"downloadImageSlice",
    initialState: initialState,
    extraReducers: {
        [downloadPp.pending] : (state, action) => {
            state.loadingPp = true;
        },
        [downloadPp.fulfilled] : (state, action) => {
            state.dataPP = action.payload;
            state.loadingPp = false;
        },
        [downloadPp.rejected] : (state, action) => {
            state.loadingPp = false;
        },

        [downloadKtp.pending] : (state, action) => {
            state.loadingKtp = true;
        },
        [downloadKtp.fulfilled] : (state, action) => {
            state.dataKtp = action.payload;
            state.loadingKtp = false;
        },
        [downloadKtp.rejected] : (state, action) => {
            state.loadingKtp = false;
        },

        [downloadKk.pending] : (state, action) => {
            state.loadingKk = true;
        },
        [downloadKk.fulfilled] : (state, action) => {
            state.dataKk = action.payload;
            state.loadingKk = false;
        },
        [downloadKk.rejected] : (state, action) => {
            state.loadingKk = false;
        },

        [downloadTranskip.pending] : (state, action) => {
            state.loadingTranskip = true;
        },
        [downloadTranskip.fulfilled] : (state, action) => {
            state.dataTranskip = action.payload;
            state.loadingTranskip = false;
        },
        [downloadTranskip.rejected] : (state, action) => {
            state.loadingTranskip = false;
        },

        [downloadIjasah.pending] : (state, action) => {
            state.loadingIjasah = true;
        },
        [downloadIjasah.fulfilled] : (state, action) => {
            state.dataIjasah = action.payload;
            state.loadingIjasah = false;
        },
        [downloadIjasah.rejected] : (state, action) => {
            state.loadingIjasah = false;
        },

        [downloadSuratIzin.pending] : (state, action) => {
            state.loadingSuratIzin = true;
        },
        [downloadSuratIzin.fulfilled] : (state, action) => {
            state.dataSuratIzin = action.payload;
            state.loadingSuratIzin = false;
        },
        [downloadSuratIzin.rejected] : (state, action) => {
            state.loadingSuratIzin = false;
        },

        
        [downloadSuratPindah.pending] : (state, action) => {
            state.loadingSuratPindah = true;
        },
        [downloadSuratPindah.fulfilled] : (state, action) => {
            state.dataSuratPindah = action.payload;
            state.loadingSuratPindah = false;
        },
        [downloadSuratPindah.rejected] : (state, action) => {
            state.loadingSuratPindah = false;
        },


        [downloadTranskipNilai.pending] : (state, action) => {
            state.loadingTranskipNilai = true;
        },
        [downloadTranskipNilai.fulfilled] : (state, action) => {
            state.dataTranskipNilai = action.payload;
            state.loadingTranskipNilai = false;
        },
        [downloadTranskipNilai.rejected] : (state, action) => {
            state.loadingTranskipNilai = false;
        },
    }
})

export const downloadImageSelector = (state) => state.downloadImage;
export default downloadImageSlice.reducer;