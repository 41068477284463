import {dashboardRoutes} from "./routes/dashboardRoutes"
import {authenticationRoutes} from "./routes/authentication"
import {errorRoutes} from "./routes/error"
import {masterDataRoutes} from "./routes/masterData"
import {publicRoute} from "./routes/publicRoutes"
import {adminRoutes} from "./routes/adminRoutes";
import {userViewRoutes} from "./routes/userViewRoutes";

export const publicRoutes = [
    ...publicRoute
]

export const protectedRoutes = [
    ...dashboardRoutes,
    ...authenticationRoutes,
    ...errorRoutes,
    ...masterDataRoutes,
    ...adminRoutes,
    ...userViewRoutes
]