import jwtAuthAxios from "../../../services/jwtAuth";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getDataUjian = createAsyncThunk("ujian/getData", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get('v1/view/cbt/hasil/user');
    return response.data;
});
export const getPaymentRegistration =
    createAsyncThunk("payment/registration", async() => {
        let token = await localStorage.getItem('auth_token');
        jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        const response = await jwtAuthAxios.get('v1/payment/check/registrasi');
        return response.data;
    });

const initialState = {
    loadingUjian : false,
    responseUjian : {},
    loading : false,
    paymentRegistration : {}
}

const ujianSlice = createSlice({
    name:"ujianSlice",
    initialState: initialState,
    extraReducers: {
        [getDataUjian.pending] : (state, action) => {
            state.loadingUjian = true;
        },
        [getDataUjian.fulfilled] : (state, action) => {
            state.responseUjian = action.payload;
            state.loadingUjian = false;
        },
        [getDataUjian.pending] : (state, action) => {
            state.loadingUjian = true;
        },
        [getPaymentRegistration.pending] : (state, action) => {
            state.loading = true;
        },
        [getPaymentRegistration.fulfilled] : (state, action) => {
            state.paymentRegistration = action.payload;
            state.loading = false;
        },
        [getPaymentRegistration.pending] : (state, action) => {
            state.loading = true;
        },
    }
})

export const ujianSelector = (state) => state.ujian;
export default ujianSlice.reducer;