import jwtAuthAxios from "../../../services/jwtAuth";
import {createSlice, createAsyncThunk } from '@reduxjs/toolkit';


export const getListPagination = createAsyncThunk("editPay/getListPagination", async({ page, pageSize }) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/vpay/pageable?page=${page}&size=${pageSize}`);
    return response.data;
});

export const findByParam = createAsyncThunk("editPay/findByParam", async(request) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.post(`v1/vpay/find/param`,request);
    return response.data;
});

const initialState = {
    editPayData: [],
    filterEditPay:[],
    editPayLoading: false,
    editPayError: null
}

const editPaySlice = createSlice({
    name: "editPay",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListPagination.pending, (state, action) => {
                state.editPayLoading = true
            })
            .addCase(getListPagination.fulfilled, (state, action) => {
                state.editPayData = action.payload
                state.editPayLoading = false
            })
            .addCase(getListPagination.rejected, (state, action) => {
                state.editPayLoading = false
                state.editPayError = action.error
            })
        builder
            .addCase(findByParam.pending, (state, action) => {
                state.editPayLoading = true
            })
            .addCase(findByParam.fulfilled, (state, action) => {
                state.editPayData = action.payload
                state.editPayLoading = false
            })
            .addCase(findByParam.rejected, (state, action) => {
                state.editPayLoading = false
                state.editPayError = action.error
            })
    }
});

export const editPaySelectors = (state) => state.editPay;
export default editPaySlice.reducer;
