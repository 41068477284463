import jwtAuthAxios from "../../services/jwtAuth";
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

export const getAuthDikti = createAsyncThunk("authdikti/getAll", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get('v1/dikti');
    return response.data;
});
export const getAuthDiktiById = createAsyncThunk("authdikti/getById", async(id) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/dikti/${id}`);
    return response.data;
});

export const deleteAuthDikti = createAsyncThunk("authdikti/delete", async(id) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    await jwtAuthAxios.delete(`v1/dikti/${id}`);
    return id;
});

export const saveAuthDikti = createAsyncThunk("authdikti/save", async(payload) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.post('v1/dikti',payload);
    return response.data;
});
export const updateAuthDikti = createAsyncThunk("authdikti/update", async(request) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.put('v1/dikti',request);
    return response.data;
});

const authdiktiEntity = createEntityAdapter({
    selectId: (authdikti) => authdikti.id
});

const authdiktiSlice = createSlice({
    name:"authdikti",
    initialState: authdiktiEntity.getInitialState,
    extraReducers: {
        [getAuthDikti.pending] : (state, action) => {
            state.loading = true;
        },
        [getAuthDikti.fulfilled] : (state, action) => {
            authdiktiEntity.setAll(state,action.payload);
            state.loading = false;
        },
        [getAuthDiktiById.fulfilled] : (state, action) => {
            authdiktiEntity.setOne(state,action.payload);
        },
        [saveAuthDikti.fulfilled] : (state, action) => {
            authdiktiEntity.addOne(state,action.payload);
        },
        [deleteAuthDikti.fulfilled] : (state, action) => {
            authdiktiEntity.removeOne(state,action.payload);
        },
        [updateAuthDikti.fulfilled] : (state, action) => {
            authdiktiEntity.upsertOne(state,{id:action.payload.id, updates:action.payload});
        }
    }
})

export const authdiktiSelectors = authdiktiEntity.getSelectors(state => state.authdikti);
export default authdiktiSlice.reducer;