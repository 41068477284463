import jwtAuthAxios from "../../../services/jwtAuth";
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

export const getDashboardPayment = createAsyncThunk("dashPay/getData", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/dashboard/payment`);
    return response.data;
});

const initialState = {
    loadingDashPayment : false,
    responseDashPayment : {}
}

const dashPaymentSlice = createSlice({
    name:"dashPaymentSlice",
    initialState: initialState,
    extraReducers: {
        [getDashboardPayment.pending] : (state, action) => {
            state.loadingDashPayment = true;
        },
        [getDashboardPayment.fulfilled] : (state, action) => {
            state.responseDashPayment = action.payload;
            state.loadingDashPayment = false;
        },
        [getDashboardPayment.pending] : (state, action) => {
            state.loadingDashPayment = true;
        },
    }
})

export const dashPaymentSelector = (state) => state.dashPayment;
export default dashPaymentSlice.reducer;