import jwtAuthAxios from "../../services/jwtAuth";
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

export const getUserById = createAsyncThunk("registrasiUlang/getUserById", async(id) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/pendaftar/show/${id}`);
    return response.data;
});

const initialState = {
    loading : false,
    dataGetById : [],
    selectedDataById : {}
}

const registrasiUlangSlice = createSlice({
    name:"registrasiUlang",
    initialState: initialState,
    extraReducers: {
        [getUserById.pending] : (state, action) => {
            state.loading = true;
        },
        [getUserById.fulfilled] : (state, action) => {
            state.selectedDataById = action.payload;
            state.loading = false;
        },
        [getUserById.pending] : (state, action) => {
            state.loading = true;
        },
    }
})

export const registrasiUlangSelector = (state) => state.registrasiUlang;
export default registrasiUlangSlice.reducer;