import jwtAuthAxios from "../../services/jwtAuth";
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

export const getUserAccess = createAsyncThunk("useraccess/getAll", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get('v1/users');
    return response.data;
});

export const checkUsernameAvailability = createAsyncThunk("useraccess/checkUsernameAvailability", async (username) => {
    try {
        let token = await localStorage.getItem('auth_token');
        jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        const response = await jwtAuthAxios.get(`v1/users/check-username/${username}`);
        
        return response.data;
    } catch (error) {
        // Handle errors if necessary
        throw error;
    }
});

export const getDefaultPassword = createAsyncThunk('userAccess/getDefaultPassword',async () => {
      try {
        const response = await jwtAuthAxios.get('v1/app-config/find/default-password'); 
        return response.data.value;
      } catch (error) {
        throw error;
      }
    }
  );
  

export const getUserAccessById = createAsyncThunk("useraccess/getById", async(id) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/users/${id}`);
    // console.log("response : ",JSON.stringify(response.data))
    return response.data;
});

export const deleteUserAccess = createAsyncThunk("useraccess/delete", async(id) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    await jwtAuthAxios.delete(`v1/users/${id}`);
    return id;
});

export const saveUserAccess = createAsyncThunk("useraccess/save", async(payload) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.post('v1/users',payload);
    return response.data;
});
export const updateUserAccess = createAsyncThunk("useraccess/update", async(request) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    // console.log("request : ",request)
    const response = await jwtAuthAxios.put('v1/users',request);
    return response.data;
});

const useraccessEntity = createEntityAdapter({
    selectId: (useraccess) => useraccess.id
});

const initialState = {
    listUser:[],
    listUserAccessById : [],
    defaultPassword: []
}

const useraccessSlice = createSlice({
    name:"useraccess",
    initialState: initialState,
    extraReducers: {
        [getUserAccess.pending] : (state, action) => {
            state.loading = true;
        },
        [getUserAccess.fulfilled] : (state, action) => {
            state.listUser = action.payload
            state.loading = false;
        },
        [getUserAccessById.pending] : (state, action) => {
            state.loading = true
        },
        [getUserAccessById.fulfilled] : (state, action) => {
            state.loading = false
            state.listUserAccessById = action.payload
        },
        [saveUserAccess.fulfilled] : (state, action) => {
            // useraccessEntity.addOne(state,action.payload);
        },
        [deleteUserAccess.fulfilled] : (state, action) => {
            // useraccessEntity.removeOne(state,action.payload);
        },
        [updateUserAccess.fulfilled] : (state, action) => {
            // useraccessEntity.upsertOne(state,{id:action.payload.id, updates:action.payload});
        },
        [checkUsernameAvailability.fulfilled]: (state, action) => {
            
        },
        [checkUsernameAvailability.rejected]: (state, action) => {
        
        },
        [getDefaultPassword.fulfilled] : (state, action) => {
            state.defaultPassword = action.payload;
        },
        [getDefaultPassword.rejected] : (state, action) => {
            state.defaultPassword = '';
        }
    }
})
export const selectUser = (state) => state.useraccess;
export default useraccessSlice.reducer;