import jwtAuthAxios from "../../services/jwtAuth";
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

export const getSemester = createAsyncThunk("kurikulum/getAll", async() => {
    try{
        let token = await localStorage.getItem('auth_token');
        jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        const response = await jwtAuthAxios.get('v1/semester')
        return response.data
    } catch(response){
        throw response
    }
});

const initialState = {
    loading: false,
    dataSemester: []
    
}

const semesterSlice = createSlice({
    name:"semester",
    initialState: initialState,
    extraReducers: (builder) => {
    builder
        .addCase(getSemester.pending, (state) => {
            state.loading = true;
        })
        .addCase(getSemester.fulfilled, (state, action) => {
            state.dataSemester = action.payload;
            state.loading = false;
        })
        .addCase(getSemester.rejected, (state, action) => {
            state.loading = false;
            console.error("Error during getMatkul:", action.error);
        })
    }
})
export const semesterSelector = (state) => state.semester;
export default semesterSlice.reducer;